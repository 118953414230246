import React,{Component} from 'react'
import { Table } from 'antd';
import reqwest from 'reqwest';

const columnsCondtions = [
{
title: 'N°',
dataIndex: 'numero',
sorter: true,
width: '10%',

},
{
title: 'Titre',
dataIndex: 'titre',
width: '30%',
},
{
title: 'Actions',
dataIndex: 'action',
sorter: true,
render: () => {
return(
<div className="cta-action-buttons">
    <button className="action-button" style={{background:"#383495",width:"40%"}}>Modifer</button>
    <button className="action-button" style={{background:"#ff0f2e",width:"40%"}}>Supprimer</button>
</div>
)
},
width: '50%',
},

];

const columnsOptions = [
{
title: 'N°',
dataIndex: 'numero',
sorter: true,
width: '10%',

},
{
title: 'Nom',
dataIndex: 'nom',
width: '20%',
},
{
title: 'Description',
dataIndex: 'description',
width: '20%',
},
{
title: 'Type',
dataIndex: 'type',
width: '20%',
},
{
title: 'Actions',
dataIndex: 'action',
sorter: true,
render: () => {
return(
<div className="cta-action-buttons">
    <button className="action-button" style={{background:"#383495",width:"40%"}}>Afficher</button>
    <button className="action-button" style={{background:"#383495",width:"40%"}}>Modifier</button>
</div>
)
},
width: '50%',
},

];
const getRandomuserParams = params => {
return {
results: params.pagination.pageSize,
page: params.pagination.current,
...params,
};
};
class DetailPrestation extends Component {
state = {
data: [],
pagination: {
current: 1,
pageSize: 10,
},
loading: false,
};
componentDidMount() {
const { pagination } = this.state;
this.fetch({ pagination });
}
handleTableChange = (pagination, filters, sorter) => {
this.fetch({
sortField: sorter.field,
sortOrder: sorter.order,
pagination,
...filters,
});
};
fetch = (params = {}) => {
this.setState({ loading: true });
reqwest({
url: 'https://randomuser.me/api',
method: 'get',
type: 'json',
data: getRandomuserParams(params),
}).then(data => {
this.setState({
loading: false,
data: data.results,
pagination: {
...params.pagination,
total: 200,
// 200 is mock data, you should read it from server
// total: data.totalCount,
},
});
});
};
render(){
const { data, pagination, loading } = this.state;
return (
<div className="main">
    <div className="container">
        <h2>Prestation</h2>
        <div className="tableau">

            <table className="tableau-info">
                <tbody>
                    <tr>
                        <td className="info-title">Id</td>
                        <td className="info-data">2</td>
                    </tr>
                    <tr>
                        <td className="info-title">Service</td>
                        <td className="info-data">Bricoleur</td>
                    </tr>
                    <tr>
                        <td className="info-title">Nom</td>
                        <td className="info-data">Assembler un/des meubles</td>

                    </tr>
                    <tr>
                        <td className="info-title">Description</td>
                        <td className="info-data">J'ai besoin d'un bricoleur pour assembler</td>
                    </tr>

                </tbody>
            </table>
            <div className="bloc">
                <div className="bloc-left">
                    <div className="profil-title">
                        <h3>Conditionss générales d'utilisation</h3>
                    </div>
                </div>
                <div className="bloc-right">
                    <div className="listing-div">
                        <div className="search-button">
                            <span> Recherche</span> <input type="search" />
                        </div>
                        <Table columns={columnsCondtions} rowKey={record=> record.login.uuid}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onChange={this.handleTableChange}
                            />
                         
                    </div>
                </div>

            </div>
            <div className="bloc">
                <div className="bloc-left">
                    <div className="profil-title">
                        <h3>Liste des options</h3>
                    </div>
                </div>
                <div className="bloc-right">
                    <div className="listing-div">
                        <div className="search-button">
                            <span> Recherche</span> <input type="search" />
                        </div>
                        <Table columns={columnsOptions} rowKey={record=> record.login.uuid}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onChange={this.handleTableChange}
                            />
                            <div className="cta-bottom-buttons" style={{paddingTop:"20px"}}>
                                <button className="action-button" style={{width:"30%",background:"#383495"}}>créer une
                                    nouvelle option</button>
                            </div>
                    </div>
                </div>

            </div>
            
            <div style={{clear:"both"}}></div>

            <div className="bloc">
                <div className="bloc-left">
                    <div className="profil-title">
                        <h3>Schéma de tarification</h3>
                    </div>
                </div>
                <div className="bloc-right">
                    <div className="listing-div prestation">
                        <p>Schéma de tarification actuel <span>Tarif par bloc de temps</span>
                        </p>

                        <label>TVA</label>
                        <input type="text" placeholder="0%" />

                        <label>Prix</label>
                        <input type="text" placeholder="45" />

                        <label>Charge supplémentaire</label>
                        <input type="text" placeholder="0" />

                        <label>Charge supplémentaire</label>
                        <input type="text" placeholder="0" />
                        <div className="recompense">
                            <p>Majoration de la récompense</p>
                            <label className="maj-label">Du :</label>
                            <input className="maj-input" type="text" placeholder="Jour Début" />
                            <input className="maj-input" type="text" placeholder="Heure Début" />
                            <label style={{marginLeft:"5px"}} className="maj-label">Au :</label>
                            <input className="maj-input" type="text" placeholder="Jour Fin" />
                            <input className="maj-input" type="text" placeholder="Heure Fin" />
                            <input className="maj-input" type="text" placeholder="0" />
                            <span>%</span>
                            <div className="cta-bottom-buttons" style={{paddingTop:"20px"}}>
                                <button className="action-button"
                                    style={{width:"20%",background:"#383495"}}>Appliquer</button>

                            </div>
                        </div>
                       <table className="grille">
                           <thead>
                               <tr>
                                   <td>Heure</td>
                                   <td>Lundi</td>
                                   <td>Mardi</td>
                                   <td>Mercredi</td>
                                   <td>Jeudi</td>
                                   <td>Vendredi</td>
                                   <td>Samedi</td>
                                   <td>Dimanche</td>
                               </tr>
                           </thead>
                           <tbody>
                               <tr>
                                   <td>00:00</td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>

                               </tr>
                               <tr>
                                   <td>01:00</td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>

                               </tr>
                               <tr>
                                   <td>02:00</td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>
                                   <td><span>0 %</span></td>

                               </tr>
                           </tbody>
                       </table>
                       <div className="cta-bottom-buttons" style={{paddingTop:"20px"}}>
                                <button className="action-button"
                                    style={{width:"40%",background:"#383495"}}>Enregistrer les modifications</button>
                            </div>

                    </div>
                </div>

            </div>
        </div>
        <div className="cta-bottom-buttons" style={{paddingTop:"20px"}}>
          <button className="action-button" style={{width:"20%",background:"#383495"}} >Modifier</button>
          <button  className="action-button" style={{width:"20%",background:"#ff0f2e"}}  >Supprimer</button>
      </div>
    </div>

</div>
)
}
}

export default DetailPrestation
import React, { Component } from 'react'
import { Table, message, Modal, Button } from 'antd';
import Mutation from '../../../lib/Mutation';
import Querry from '../../../lib/Querry';
import { DownloadOutlined, ExclamationCircleOutlined, RestOutlined } from '@ant-design/icons';

const defaultURL = 'https://connect.heroes.swiss/uploads/';
const { confirm } = Modal;
var _ = require('lodash');

const getRandomuserParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
    };
};

class Facture extends Component {
    state = {
        data: [],
        pagination: {
            current: 1,
            pageSize: 10,
        },
        loading: false,
    };
    componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
    }
   
    handleTableChange = (pagination, filters, sorter) => {
        let allFactures = [...this.state.data]
        if (pagination && pagination.filters && pagination.filters !== undefined && pagination.filters.length > 0) {
            allFactures = allFactures.filter(e => {
                let date = new Date(e.dateCreation)
                let dateFact = `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
                return e.numeroFacture.toLowerCase().includes(pagination.filters.toLowerCase()) ||
                    e.typeFacture.toLowerCase().includes(pagination.filters.toLowerCase()) ||
                    dateFact.toLowerCase().includes(pagination.filters.toLowerCase());
            })
        }else {this.fetch()}   
        
        this.setState({
            data: allFactures && allFactures.length > 0 ? allFactures :  _.reverse(allFactures),
            pagination: {
                ...pagination,
                total: allFactures && allFactures.length ? allFactures.length : 0
            },
            filters,
        });                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
    };

    fetch = (params = {}) => {
        this.setState({ loading: true });
        let statutString = '';
        if (params.statut) {
            params.statut.forEach(st => {
                statutString += st + ' ';
            });
        }
        Querry.GetFacturesTermine(params.sortField ?
            { statutString, sortField: params.sortField + (params.sortOrder === 'ascend' ? '_ASC' : '_DESC') } :
            (params.pagination && params.pagination.filters ? { filters: params.pagination.filters } : { statutString }))
            .then(data => {
                this.setState({
                    loading: false,
                    data: data,
                    pagination: {
                        ...params.pagination,
                        total: data && data.length ? data.length : 0,
                        // 200 is mock data, you should read it from server
                        // total: data.totalCount,
                    },
                });
            });
    };

    deleteFacture = (id) => {
        confirm({
            title: 'Etes vous sûr de vouloir supprimer cette facture ?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                Mutation.deleteFactures(id).then(result => {
                    message.success("Facture supprimée !")
                    setTimeout(() => {
                        window.location.reload()
                    }, 100)
                }).catch(er => {
                    message.error("Erreur de l'opération, veuillez réessayer plus tard !");
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {

        const columnsFactures = [

            {
                title: 'Numéro de facture',
                dataIndex: 'numeroFacture',
                sorter: true,
                width: '20%',
            },
            {
                title: 'Type de facture',
                dataIndex: 'typeFacture',
                sorter: true,
                width: '20%',
            },
            {
                title: 'Date de facturation',
                dataIndex: 'dateCreation',
                sorter: true,
                width: '20%',
                render: (dateCreation) => {
                    let date = new Date(dateCreation)
                   return  (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + '-' + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : +(date.getMonth() + 1)) + '-' + date.getFullYear();
                },
            },
            {
                title: 'Montant',
                dataIndex: 'montant',
                sorter: true,
                width: '20%',
                render: (montant) => {
                    return (
                            <div>
                                {montant.toFixed(2) + ' CHF'}
                            </div>
                    )
                },
            },

            {
                title: 'Actions',
                dataIndex: 'fileName',
                sorter: true,
                render: (fileName) => {
                    return (fileName &&
                        <div className="download">
                            <a href={defaultURL + fileName} download={fileName}> <Button type="primary" shape="round" icon={<DownloadOutlined />} size="large" /></a>
                        </div>
                    )
                },
                width: '10%',
            },
            {
                title: 'Actions',
                dataIndex: 'id',
                sorter: true,
                render: (id) => {
                    return (id &&
                        <div className="cta-action-buttons">
                            <Button danger type="primary" shape="round" icon={<RestOutlined />} size="large" onClick={() => this.deleteFacture(id)} />
                        </div>
                    )
                },
                width: '10%',
            },
        ];

        const { data, pagination, loading, filters, sorter } = this.state;
        return (
            <div className="main">
                <div className="container">
                    <div className="tableau">
                        <h2>Liste des factures</h2>
                        <div className="bloc">

                            <div style={{ width: "100%" }} className="bloc-right">
                                <div className="listing-div">
                                    <div className="search-button">
                                        <span> Recherche : </span>
                                        <input type="search" 
                                        onChange={e => this.handleTableChange({ filters: e.target.value }, filters, sorter)} 
                                        placeholder="Recherche par numéro, type ou date de facture"
                                        />
                                    </div>
                                    <Table columns={columnsFactures} rowKey={record => record.id}
                                        dataSource={data}
                                        pagination={pagination}
                                        loading={loading}
                                        onChange={this.handleTableChange}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        )
    }
}

export default Facture

import React, { Component } from 'react'
import Heros from '../../../assets/images/test.png'
import { Table } from 'antd';
import reqwest from 'reqwest';


const columnsPrestations = [
{
title: 'Prestation',
dataIndex: 'prestation',
sorter: true,
width: '20%',

},
{
title: 'Description',
dataIndex: 'description',
width: '20%',
},
{
title: 'Actions',
dataIndex: 'action',
sorter: true,
render: () => {
return(
<div className="cta-action-buttons">
    <button className="action-button" style={{background:"#383495",width:"40%"}}>Voir détails de la prestation</button>
    <button className="action-button" style={{background:"#383495",width:"40%"}}>Modifier prestation</button>
    <button className="action-button" style={{background:"#ff0f2e",width:"40%"}}>Supprimer prestation</button>
</div>
)
},
width: '50%',
},

];
const columnsPrestataires = [

{
title: 'Nom',
dataIndex: 'prestation',
sorter: true,
width: '50%',

},

{
title: 'Actions',
dataIndex: 'action',
sorter: true,
render: () => {
return(
<div className="cta-action-buttons">
   
    <button className="action-button" style={{background:"#ff0f2e",width:"40%"}}>Supprimer</button>
</div>
)
},
width: '50%',
},

];

const getRandomuserParams = params => {
return {
results: params.pagination.pageSize,
page: params.pagination.current,
...params,
};
};
class DetailService extends Component {
state = {
data: [],
pagination: {
current: 1,
pageSize: 10,
},
loading: false,
};
componentDidMount() {
const { pagination } = this.state;
this.fetch({ pagination });
}
handleTableChange = (pagination, filters, sorter) => {
this.fetch({
sortField: sorter.field,
sortOrder: sorter.order,
pagination,
...filters,
});
};
fetch = (params = {}) => {
this.setState({ loading: true });
reqwest({
url: 'https://randomuser.me/api',
method: 'get',
type: 'json',
data: getRandomuserParams(params),
}).then(data => {
this.setState({
loading: false,
data: data.results,
pagination: {
...params.pagination,
total: 200,
// 200 is mock data, you should read it from server
// total: data.totalCount,
},
});
});
};

render() {
const { data, pagination, loading } = this.state;
return (
<div className="main">
    <div className="container">
        <div className="tableau">
            <h2>Détails du service</h2>
            <div className="bloc">
                <div className="bloc-left">
                    <div className="profil-name">Nom</div>
                    <div className="profil-title">
                        <h3>Photo</h3>
                    </div>
                </div>
                <div className="bloc-right">
                    <div className="service-name">Bricoleur</div>
                    <div className="profil-picture">
                        <img className="img-thumbnail" src={Heros} alt="heros" />
                    </div>
                </div>
            </div>

            <div className="bloc">
                <div className="bloc-left">
                    <div className="profil-title">
                        <h3>Liste des prestations</h3>
                    </div>
                </div>
                <div className="bloc-right">
                    <div className="listing-div">
                        <div className="search-button">
                            <span> Recherche</span> <input type="search" />
                        </div>
                        <Table columns={columnsPrestations} rowKey={record=> record.login.uuid}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onChange={this.handleTableChange}
                            />
                            <div className="cta-bottom-buttons" style={{paddingTop:"20px"}}>
                                <button className="action-button" style={{width:"20%",background:"#383495"}}>Ajouter
                                    prestation</button>
                            </div>
                    </div>

                </div>

            </div>
            <div style={{float:"left", width:"30%"}} className="bloc-left">
               <p style={{padding:"20px 0"}}>Assigner un prestataire</p> 
            </div>
            <div style={{float:"left",padding:"20px", width:"70%"}} className="bloc-right">
              <form className="assigning-pres">
                  <input className="pres-input" type="text" placeholder="prestataire"/>
                  <input className="pres-submit" type="submit" value="Enregistrer"/>
              </form>
            </div>
        <div style={{clear:"both"}}></div>
        <div className="bloc">
                <div className="bloc-left">
                    <div className="profil-title">
                        <h3>Liste des prestataires</h3>
                    </div>
                </div>
                <div className="bloc-right">
                    <div className="listing-div">
                        <div className="search-button">
                            <span> Recherche</span> <input type="search" />
                        </div>
                        <Table columns={columnsPrestataires} rowKey={record=> record.login.uuid}
                            dataSource={data}
                            pagination={pagination}
                            loading={loading}
                            onChange={this.handleTableChange}
                            />
                    </div>

                </div>

            </div>
            
        </div>
        <div className="cta-bottom-buttons" style={{paddingTop:"20px"}}>
          <button  className="action-button" style={{width:"20%",background:"#383495"}}  >Modifier service</button>
          <button  className="action-button" style={{width:"20%",background:"#ff0f2e"}}  >Supprimer service</button>
      </div>
    </div>
</div>
)
}

}
export default DetailService
import React, { Component } from 'react'
import Heros from '../../../assets/images/heroless.png'
import { Table, Modal, Button, message } from 'antd';
import { Link, Router } from 'react-router-dom';
import Api from '../../../lib/Api';
import Querry from '../../../lib/Querry';
import Mutation from '../../../lib/Mutation';
import StarRatings from 'react-star-ratings';
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

// Import the main component
import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';


var _ = require('lodash');

const baseUrl = Api.MailURL;

const columnsPrestations = [

    {
        title: 'Service',
        dataIndex: 'service',
        render: (service) => {
            return service.titre ? service.titre : ''
        },
        sorter: true,
        width: '10%',
    },
    {
        title: 'Prestation',
        dataIndex: 'titre',
        sorter: true,
        width: '30%',
    },
    {
        title: 'Total reversé au prestataire',
        dataIndex: 'reversePrestataire',
        render: (reversePrestataire) => {
            return 'CHF ' + reversePrestataire
        },
        sorter: true,
        width: '10%',
    },
    {
        title: 'Etat',
        dataIndex: 'statut',
        sorter: true,
        width: '10%',
    },
    {
        title: 'Actions',
        dataIndex: 'id',
        sorter: true,
        render: () => {
            return (
                <div className="cta-action-buttons">
                    <button className="action-button" style={{ background: "#383495", width: "40%" }}>Dossier</button>
                    <button className="action-button" style={{ background: "#ff0f2e", width: "40%" }}>Supprimer</button>
                </div>
            )
        },
        width: '50%',
    },

];
const columnsEvaluations = [

    {
        title: 'Prestation',
        dataIndex: 'titre',
        sorter: true,
        width: '30%',

    },

    {
        title: 'Mandataire',
        dataIndex: 'mandataire',
        render: (mandataire) => {
            return mandataire ? (mandataire.prenom + ' ' + mandataire.nom) : ''
        },
        sorter: true,
        width: '20%',

    },
    {
        title: 'Note',
        dataIndex: 'notation',
        render: (notation) => {
            return <StarRatings
                starRatedColor="#FF0032"
                rating={notation}
                starDimension="15px"
            />
        },
        sorter: true,
        width: '30%',

    },
    {
        title: 'Commentaire',
        dataIndex: 'commentaires',
        render: (commentaires) => {
            return commentaires && commentaires[0] && commentaires[0].contenu && commentaires[0].contenu !== '' ? commentaires[0].contenu : 'Pas de commentaire'
        },
        sorter: true,
        width: '60%',

    },

];
const columnsDemandes = [
    {
        title: 'Prestation',
        dataIndex: 'titre',
        sorter: true,
        width: '25%',

    },
    {
        title: 'Date',
        dataIndex: 'dateDeLaPrestation',
        sorter: true,
        render: (dateDeLaPrestation) => {
            let date = new Date(dateDeLaPrestation);
            return date ? (date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()) : '--/--/----'
        },
        width: '15%',

    },
    {
        title: 'Temps',
        dataIndex: 'dureeDeLaPrestation',
        render: (dureeDeLaPrestation) => {
            return dureeDeLaPrestation ? dureeDeLaPrestation : '--:--'
        },
        sorter: true,
        width: '15%',

    },
    {
        title: 'Statut',
        dataIndex: 'statut',
        sorter: true,
        width: '15%',

    },
    {
        title: 'Actions',
        dataIndex: 'id',
        sorter: true,
        render: () => {
            return (
                <div className="cta-action-buttons">
                    <button className="action-button" style={{ background: "#383495", width: "40%" }}>Dossier</button>
                </div>
            )
        },
        width: '50%',
    }

];
const columnsFactures = [
    {
        title: 'Prestation',
        dataIndex: 'titre',
        sorter: true,
        width: '30%',

    },
    {
        title: 'En tant que',
        dataIndex: 'type',
        sorter: true,
        width: '15%',

    },
    {
        title: 'Date',
        dataIndex: 'dateDeLaPrestation',
        sorter: true,
        render: (dateDeLaPrestation) => {
            let date = new Date(dateDeLaPrestation);
            return date ? (date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()) : '--/--/----'
        },
        sorter: true,
        width: '20%',

    },
    {
        title: 'Actions',
        dataIndex: 'id',
        sorter: true,
        render: () => {
            return (
                <div className="cta-action-buttons">
                    <button className="action-button" style={{ background: "#ff0f2e", width: "40%" }}>Voir la facture</button>
                </div>
            )
        },
        width: '50%',
    }
];



const getRandomuserParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
    };
};
const { confirm } = Modal;


class ProfilPro extends Component {

    state = {
        data: [],
        user: {},
        paginationPrestation: {
            current: 1,
            pageSize: 3,
        },
        paginationNotation: {
            current: 1,
            pageSize: 3,
        },
        paginationMandataire: {
            current: 1,
            pageSize: 3,
        },
        paginationFacture: {
            current: 1,
            pageSize: 3,
        },
        paginationPJ: {
            current: 1,
            pageSize: 3,
        },
        paginationService: {
            current: 1,
            pageSize: 3,
        },
        userId: '',
        isPictureToShow: false,
        loadingNotation: false,
        loadingFacture: false,
        loadingPrestation: false,
        loadingMandataire: false,
        loadingService: false,
        loadingPJ: false,
        validateAccount: false,
        deletedAccount: false,
        changementAdresses: [],
        profil: "",
        typeCompte: ""
    };

    getPJColumns() {
        return [
            {
                title: 'Type',
                dataIndex: 'type',
                sorter: true,
                width: '30%',

            },
            {
                title: 'Document',
                dataIndex: 'PJ',
                render: (PJ) => {
                    return PJ.filename ? PJ.filename.split('.')[PJ.filename.split('.').length - 1].toUpperCase() : '---'
                },
                sorter: true,
                width: '15%',

            },
            {
                title: 'Nom fichier',
                dataIndex: 'PJ',
                render: (PJ) => {
                    return PJ.filename
                },
                sorter: true,
                width: '30%',

            },
            {
                title: 'Actions',
                dataIndex: 'id',
                sorter: true,
                render: (id) => {
                    return (
                        <div className="cta-action-buttons">
                            <button onClick={e => {
                                this.setState({
                                    isPictureToShow: true,
                                    currentDocType: this.state.user && this.state.user.pieceJointes && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id) && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).PJ && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).PJ.filename ? (this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).PJ.filename.split('.')[this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).PJ.filename.split('.').length - 1]) : '',
                                    currentDocTitle: this.state.user && this.state.user.pieceJointes && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id) && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).type ? this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).type : '',
                                    currentDoc: this.state.user && this.state.user.pieceJointes && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id) && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).PJ && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).PJ.filename ? (baseUrl + 'uploads/' + this.state.user.pieceJointes.find(pieceJoint => pieceJoint.id === id).PJ.filename) : ''
                                })
                            }} className="action-button" style={{ background: "#383495", width: "40%" }}>Afficher</button>
                        </div>
                    )
                },
                width: '50%',
            }
        ];
    }

    getColumnService() {
        return [
            {
                title: 'Modèle',
                dataIndex: 'service',
                render: (service) => {
                    return service && service.model ? service.model.nom : '---'
                },
                sorter: true,
                width: '20%',

            },
            {
                title: 'Service',
                dataIndex: 'service',
                render: (service) => {
                    return service ? service.titre : '---'
                },
                sorter: true,
                width: '30%',

            },
            {
                title: 'Actions',
                dataIndex: ['id', 'service'],
                sorter: true,
                render: (id, service) => {
                    return (
                        <div className="cta-action-buttons">
                            <button onClick={e => this.validerDemandeService(id, service)} className="action-button" style={{ background: "#383495", width: "40%" }}>Valider</button>
                            <button onClick={e => this.refuserDemandeService(id, service)} className="action-button" style={{ background: "#ff0f2e", width: "40%" }}>Refuser</button>
                        </div>
                    )
                },
                width: '50%',
            }
        ];
    }
    componentDidMount() {
        this.setState({ userId: this.props.match.params.id });
        const location = this.props.location;
        if(location){
            this.setState({ profil: location.state.profil, typeCompte: location.state.typeCompte })
        }
        const { pagination } = this.state;
        setTimeout(() => {
            this.fetch({ pagination });
        }, 1000)
    }

     columnsChangeAdresse(){
        return [
            {
                title: 'Code postal',
                dataIndex: 'postcode',
                sorter: true,
                width: '15%',
        
            },
            {
                title: 'rue',
                dataIndex: 'street',
                sorter: true,
                width: '25%',
        
            },
            {
                title: 'Ville',
                dataIndex: 'city',
                sorter: true,
                width: '15%',
        
            },
            {
                title: 'Pays',
                dataIndex: 'country',
                sorter: true,
                width: '15%',
        
            },
            {
                title: 'Etat',
                dataIndex: 'etat',
                sorter: true,
                width: '10%',
            },
            {
                title: 'Actions',
                dataIndex: 'id',
                sorter: true,
                render: (id) => {
                    return ( 
                        <div className="cta-action-buttons">
                            <button className="action-button" style={{ background: "#383495", width: "40%", cursor:'pointer' }}
                            onClick={()=>                           
                                confirm({
                                    title: "Validation",
                                    icon: <ExclamationCircleOutlined />,
                                    content: "Voulez-vous vraiment valider le changement d'adresse de cet utilisateur ?",
                                    okText: 'Valider',
                                    okType: 'danger',
                                    cancelText: 'Annuler',
                                    onOk() {
                                        Mutation.ValiderChangementAdresse(id).then(result=>{
                                            window.location.reload();
                                        }).catch(error => console.log(error))
                                    },
                                    onCancel() {
                                        console.log('Cancel');
                                    },
                                })
                            }
                            >Valider</button>
                            <button className="action-button" style={{ background: "#ff0f2e", width: "40%", cursor:'pointer' }}
                            onClick={()=>
                                confirm({
                                    title: "Refus",
                                    icon: <ExclamationCircleOutlined />,
                                    content: "Refuser le changement d'adresse de cet utilisateur ?",
                                    okText: 'Oui',
                                    okType: 'danger',
                                    cancelText: 'Annuler',
                                    onOk() {
                                        console.log('Demande refusée !');
                                    },
                                    onCancel() {
                                        console.log('Cancel');
                                    },
                                })
                            }
                            >Refuser</button>
                        </div>
                    )
                },
                width: '30%',
            }
        
        ];
     }
    

    validerChangementAdresse(){
        Mutation.ValiderChangementAdresse(this.state.id).then(result=>{
            console.log(result);
            const { pagination } = this.state;
            setTimeout(() => {
                this.fetch({ pagination });
            }, 20)
        }).catch(error => console.log(error))
    }

    validerDemandeService(id, data) {
        Mutation.ConnectService({ userId: this.state.userId, serviceId: data.service.id })
            .then(res => {
                Mutation.DeleteDemandeService({ serviceId: data.id }).then(res2 => {
                    message.success("Le service " + data.service.titre + " a bien été ajouté au prestataire");
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 300)
                })
                    .catch(error => {
                        message.error('Erreur!');
                    });
            })
            .catch(error => {
                message.error('Erreur!');
            });
        // ConnectService
        // DeleteDemandeService
    }

    refuserDemandeService(id, data) {
        Mutation.DeleteDemandeService({ serviceId: data.id }).then(res2 => {
            message.success("Le service " + data.service.titre + " a bien été rejeté");
            setTimeout(() => {
                window.location.reload(false);
            }, 300)
        })
            .catch(error => {
                message.error('Erreur!');
            });
    }
    showConfirm = () => {
        const history = this.props.history;
        const userId = this.state.userId;
        const profil = this.state.profil ;
        confirm({
            title: 'Etes vous sûr de vouloir supprimer ce compte ?',
            icon: <ExclamationCircleOutlined />,
            content: 'Suppression compte',
            onOk() {
                Mutation.DeletedAccount({ userId: userId, profil  })
                    .then(data => {
                        message.success("Le compte a bien été supprimé !");
                        history.goBack();
                        setTimeout(()=>{
                            window.location.reload();
                        }, 100)
                    })
                    .catch(error => {
                        message.error("Erreur de l'operation, veuillez réesseyez plus tard !");
                    })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    handlePrestationTableChange = (paginationPrestation, filters, sorter) => {
        let prestationPrestataires = this.state.allPrestationPrestataires;
        if (sorter && sorter.order) {
            prestationPrestataires = _.sortBy(prestationPrestataires, [(o) => { return o[sorter.field]; }]);
        }
        if (paginationPrestation && paginationPrestation.filters && paginationPrestation.filters !== '') {
            prestationPrestataires = prestationPrestataires && prestationPrestataires[0] ? prestationPrestataires.filter(e => {
                return e.titre.toLowerCase().includes(paginationPrestation.filters.toLowerCase()) || (e.reversePrestataire && e.reversePrestataire.toString().toLowerCase().includes(paginationPrestation.filters.toLowerCase())) || (e.service && e.service.titre && e.service.titre.toLowerCase().includes(paginationPrestation.filters.toLowerCase()))
            }) : []
        }
        this.setState({
            prestationPrestataires: sorter && prestationPrestataires && prestationPrestataires.length > 0 && sorter.order === 'ascend' ? prestationPrestataires : (sorter && sorter.order && prestationPrestataires && prestationPrestataires.length > 0 ? _.reverse(prestationPrestataires) : prestationPrestataires),
            paginationPrestation: {
                ...paginationPrestation,
                pageSize: 3,
                total: prestationPrestataires && prestationPrestataires.length ? prestationPrestataires.length : 0
            },
        });
    };

    handleMandataireTableChange = (paginationMandataire, filters, sorter) => {
        let prestationMandataires = this.state.allPrestationMandataires;
        if (sorter && sorter.order) {
            prestationMandataires = _.sortBy(prestationMandataires, [(o) => { return o[sorter.field]; }]);
        }
        if (paginationMandataire && paginationMandataire.filters && paginationMandataire.filters !== '') {
            prestationMandataires = prestationMandataires && prestationMandataires[0] ? prestationMandataires.filter(e => {
                return e.titre.toLowerCase().includes(paginationMandataire.filters.toLowerCase()) || (e.reversePrestataire && e.reversePrestataire.toString().toLowerCase().includes(paginationMandataire.filters.toLowerCase())) || (e.service && e.service.titre && e.service.titre.toLowerCase().includes(paginationMandataire.filters.toLowerCase()))
            }) : []
        }
        this.setState({
            prestationMandataires: sorter && prestationMandataires && prestationMandataires.length > 0 && sorter.order === 'ascend' ? prestationMandataires : (sorter && sorter.order && prestationMandataires && prestationMandataires.length > 0 ? _.reverse(prestationMandataires) : prestationMandataires),
            paginationMandataire: {
                ...paginationMandataire,
                pageSize: 3,
                total: prestationMandataires && prestationMandataires.length ? prestationMandataires.length : 0
            },
        });
    };

    handleFactureTableChange = (paginationFacture, filters, sorter) => {
        let factures = this.state.allFactures;
        if (sorter && sorter.order) {
            factures = _.sortBy(factures, [(o) => { return o[sorter.field]; }]);
        }
        if (paginationFacture && paginationFacture.filters && paginationFacture.filters !== '') {
            factures = factures && factures[0] ? factures.filter(e => {
                return e.titre.toLowerCase().includes(paginationFacture.filters.toLowerCase()) || (e.reversePrestataire && e.reversePrestataire.toString().toLowerCase().includes(paginationFacture.filters.toLowerCase())) || (e.service && e.service.titre && e.service.titre.toLowerCase().includes(paginationFacture.filters.toLowerCase()))
            }) : []
        }
        this.setState({
            factures: sorter && factures && factures.length > 0 && sorter.order === 'ascend' ? factures : (sorter && sorter.order && factures && factures.length > 0 ? _.reverse(factures) : factures),
            paginationFacture: {
                ...paginationFacture,
                pageSize: 3,
                total: factures && factures.length ? factures.length : 0
            },
        });
    };

    handlePJTableChange = (paginationPJ, filters, sorter) => {
        let pjs = this.state.allPjs;
        if (sorter && sorter.order) {
            pjs = _.sortBy(pjs, [(o) => { return o[sorter.field]; }]);
        }
        if (paginationPJ && paginationPJ.filters && paginationPJ.filters !== '') {
            pjs = pjs && pjs[0] ? pjs.filter(e => {
                return e.titre.toLowerCase().includes(paginationPJ.filters.toLowerCase()) || (e.reversePrestataire && e.reversePrestataire.toString().toLowerCase().includes(paginationPJ.filters.toLowerCase())) || (e.service && e.service.titre && e.service.titre.toLowerCase().includes(paginationPJ.filters.toLowerCase()))
            }) : []
        }
        this.setState({
            pjs: sorter && pjs && pjs.length > 0 && sorter.order === 'ascend' ? pjs : (sorter && sorter.order && pjs && pjs.length > 0 ? _.reverse(pjs) : pjs),
            paginationPJ: {
                ...paginationPJ,
                pageSize: 3,
                total: pjs && pjs.length ? pjs.length : 0
            },
        });
    };

    handleNotationTableChange = (paginationNotation, filters, sorter) => {
        let notations = this.state.allNotations;
        if (sorter && sorter.order) {
            notations = _.sortBy(notations, [(o) => { return o[sorter.field]; }]);
        }
        if (paginationNotation && paginationNotation.filters && paginationNotation.filters !== '') {
            notations = notations && notations[0] ? notations.filter(e => {
                return e.titre.toLowerCase().includes(paginationNotation.filters.toLowerCase()) || (e.service && e.service.titre && e.service.titre.toLowerCase().includes(paginationNotation.filters.toLowerCase()))
            }) : []
        }
        this.setState({
            notations: sorter && notations && notations.length > 0 && sorter.order === 'ascend' ? notations : (sorter && sorter.order && notations && notations.length > 0 ? _.reverse(notations) : notations),
            paginationNotation: {
                ...paginationNotation,
                pageSize: 3,
                total: notations && notations.length ? notations.length : 0
            },
        });
    };

    handleServiceTableChange = (paginationService, filters, sorter) => {
        let demandeServices = this.state.allDemandeServices;
        if (sorter && sorter.order) {
            demandeServices = _.sortBy(demandeServices, [(o) => { return o[sorter.field]; }]);
        }
        if (paginationService && paginationService.filters && paginationService.filters !== '') {
            demandeServices = demandeServices && demandeServices[0] ? demandeServices.filter(e => {
                return (e && e.titre && e.titre.toLowerCase().includes(paginationService.filters.toLowerCase())) || (e.service && e.service.titre && e.service.titre.toLowerCase().includes(paginationService.filters.toLowerCase())) || (e.service && e.service.model && e.service.model.nom && e.service.model.nom.toLowerCase().includes(paginationService.filters.toLowerCase()))
            }) : []
        }
        this.setState({
            demandeServices: sorter && demandeServices && demandeServices.length > 0 && sorter.order === 'ascend' ? demandeServices : (sorter && sorter.order && demandeServices && demandeServices.length > 0 ? _.reverse(demandeServices) : demandeServices),
            paginationService: {
                ...paginationService,
                pageSize: 3,
                total: demandeServices && demandeServices.length ? demandeServices.length : 0
            },
        });
    };

    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };
    fetch = (params = {}) => {
        this.setState({ loading: true });
        var adresses = [];
        Querry.GetUserDetails({ userId: this.state.userId, typeCompte: this.state.typeCompte })
            .then(data => {
                adresses.push(data)
                this.setState({
                    loading: false,
                    user: data,
                    prestationPrestataires: data.prestationPrestataires,
                    allPrestationPrestataires: data.prestationPrestataires,
                    notations: data.prestationPrestataires.filter(e => e.notation),
                    allNotations: data.prestationPrestataires.filter(e => e.notation),
                    prestationMandataires: data.prestationMandataires,
                    allPrestationMandataires: data.prestationMandataires,
                    pjs: data.pieceJointes,
                    allPjs: data.pieceJointes,
                    demandeServices: data.demandeServices,
                    allDemandeServices: data.demandeServices,
                    changementAdresses: adresses,
                    factures: data.prestationPrestataires.filter(e => e.facturePrestataire).map(e => ({ ...e, type: 'Prestataire' })).concat(data.prestationMandataires.filter(e => e.factureMandataire).map(e => ({ ...e, type: 'Mandataire' }))),
                    allFactures: data.prestationPrestataires.filter(e => e.facturePrestataire).map(e => ({ ...e, type: 'Prestataire' })).concat(data.prestationMandataires.filter(e => e.factureMandataire).map(e => ({ ...e, type: 'Mandataire' }))),
                    pagination: {
                        ...params.pagination,
                        total: data && data.length ? data.length : 0,
                    },
                });
            })
            .catch(error => {
                console.log(error)
            });
    };

    onDocumentLoadSuccess({ numPages }) {
        console.log(numPages);
    }

    showDeleteConfirm(service) {
        const self = this;
        confirm({
            title: "Etes vous sure de vouloir enlever le service de l'utilisateur ?",
            icon: <ExclamationCircleOutlined />,
            content: "Attention! Tu ne pourras pas rattacher à nouveau le service tant que l'utilisateur ne refasse la demande.",
            okText: 'Enlever',
            okType: 'danger',
            cancelText: 'Annuler',
            onOk() {
                Mutation.DisconnectService({ userId: self.state.userId, serviceId: service.id })
                    .then(data => {
                        message.success("Service enlevé! L'utilisateur n'a plus le service " + service.titre);
                        window.location.reload(false);
                        
                    })
                    .catch(error => {
                        message.error('Erreur!');
                    });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    changeStateUser() {
        Mutation.ChangeStateUser({ userId: this.state.user.id, etat: "ADMIN_NO_VALIDATE" })
            .then(data => {
                message.success("Prestation rejeté. Un mail lui a été envoyé !");
                setTimeout(() => {
                    window.location.reload(false);
                }, 500);
            })
            .catch(error => {
                message.error("Erreur de l'operation, veuillez réesseyez plus tard !");
            })
    }
    deletedAccount() {
        Mutation.DeletedAccount({ userId: this.state.user.id, profil: this.state.profil  })
            .then(data => {
                message.success("Le compte a bien été supprimé !");
            })
            .catch(error => {
                message.error("Erreur de l'operation, veuillez réesseyez plus tard !");
            })
    }

    render() {
        const { paginationFacture, loadingFacture, paginationService, loadingService, paginationPJ, loadingPJ, paginationPrestation, paginationMandataire, paginationNotation, loadingPrestation, loadingNotation, loadingMandataire } = this.state;
        return (
            <div className="main">
                <div className="container">
                    <div className="tableau">
                        <h2>Profil {this.state.user && this.state.user.typeRegistreCommerce !== 'Indépendant' ? 'pro' : 'lamda'}</h2>
                        <div className="bloc">
                            <div className="bloc-left">
                                <div className="profil-title">
                                    <h3>Photo</h3>
                                </div>
                            </div>
                            <div className="bloc-right">
                                <div className="profil-picture">
                                    <img className="img-thumbnail" src={this.state.user && this.state.user.pieceJointes && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.type === 'PROFIL') && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.type === 'PROFIL').PJ && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.type === 'PROFIL').PJ.filename ? (baseUrl + 'uploads/' + this.state.user.pieceJointes.find(pieceJoint => pieceJoint.type === 'PROFIL').PJ.filename) : ''} alt={this.state.user && this.state.user.pieceJointes && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.type === 'PROFIL') && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.type === 'PROFIL').PJ && this.state.user.pieceJointes.find(pieceJoint => pieceJoint.type === 'PROFIL').PJ.filename ? this.state.user.pieceJointes.find(pieceJoint => pieceJoint.type === 'PROFIL').PJ.filename : ''} />
                                </div>
                            </div>
                        </div>

                        <table className="tableau-info">
                            <tbody>
                                <tr>
                                    <td className="info-title">Nom</td>
                                    <td className="info-data">{this.state.user.prenom} {this.state.user.nom}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Email</td>
                                    <td className="info-data">{this.state.user.email}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Date de naissance</td>
                                    <td className="info-data">{this.state.user && this.state.user.birthDate ? this.state.user.birthDate : 'Non renseignée'}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">NPA</td>
                                    <td className="info-data">{this.state.user.postcode}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Rue</td>
                                    <td className="info-data">{this.state.user.street}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Numéro</td>
                                    <td className="info-data">{this.state.user.number}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Ville</td>
                                    <td className="info-data">{this.state.user.city}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Pays</td>
                                    <td className="info-data">{this.state.user.country}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Téléphone</td>
                                    <td className="info-data">{this.state.user.phone}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Statut</td>
                                    <td className="info-data">{this.state.user.etat}</td>
                                </tr>
                                <tr>
                                    <td className="info-title">Assigner une prestation</td>
                                    <td className="info-data">
                                        {
                                            this.state.user && this.state.user.services && this.state.user.services.map(service => (
                                                <div id="services">
                                                    <div className="assigned-profile" style={{ width: 'auto', margin: 5 }}>{service.titre} <button onClick={e => this.showDeleteConfirm(service)} style={{ color: 'red', border: 'none', fontSize: 20, fontWeight: 'bold' }}>x</button></div>
                                                </div>
                                            ))
                                        }
                                        {/* <div><button className="enreg-button" style={{marginTop: 40, float: 'right'}}>Gérer les services</button></div> */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="bloc">
                            <div className="bloc-left">
                                <div className="profil-title">
                                    <h3>Liste des prestations</h3>
                                </div>
                            </div>
                            <div className="bloc-right">
                                <div className="listing-div">
                                    <div className="search-button">
                                        <span> Recherche</span> <input onChange={e => this.handlePrestationTableChange({ filters: e.target.value })} type="search" />
                                    </div>
                                    <Table columns={columnsPrestations} rowKey={record => record.id}
                                        dataSource={this.state.prestationPrestataires}
                                        pagination={paginationPrestation}
                                        loading={loadingPrestation}
                                        onChange={this.handlePrestationTableChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="bloc">
                            <div className="bloc-left">
                                <div className="profil-title">
                                    <h3>Notes/évaluations des clients</h3>
                                </div>
                            </div>
                            <div className="bloc-right">
                                <div className="listing-div">
                                    <div className="search-button">
                                        <span> Recherche</span> <input onChange={e => this.handleNotationTableChange({ filters: e.target.value })} type="search" />
                                    </div>
                                    <Table columns={columnsEvaluations} rowKey={record => record.id}
                                        dataSource={this.state.notations}
                                        pagination={paginationNotation}
                                        loading={loadingNotation}
                                        onChange={this.handleNotationTableChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="bloc">
                            <div className="bloc-left">
                                <div className="profil-title">
                                    <h3>Liste des demandes</h3>
                                </div>
                            </div>
                            <div className="bloc-right">
                                <div className="listing-div">
                                    <div className="search-button">
                                        <span> Recherche</span> <input onChange={e => this.handleMandataireTableChange({ filters: e.target.value })} type="search" />
                                    </div>
                                    <Table columns={columnsDemandes} rowKey={record => record.id}
                                        dataSource={this.state.prestationMandataires}
                                        pagination={paginationMandataire}
                                        loading={loadingMandataire}
                                        onChange={this.handleMandataireTableChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="bloc">
                            <div className="bloc-left">
                                <div className="profil-title">
                                    <h3>Factures</h3>
                                </div>
                            </div>
                            <div className="bloc-right">
                                <div className="listing-div">
                                    <div className="search-button">
                                        <span> Recherche</span> <input onChange={e => this.handleFactureTableChange({ filters: e.target.value })} type="search" />
                                    </div>
                                    <Table columns={columnsFactures} rowKey={record => record.id}
                                        dataSource={this.state.factures}
                                        pagination={paginationFacture}
                                        loading={loadingFacture}
                                        onChange={this.handleFactureTableChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="bloc">
                            <div className="bloc-left">
                                <div className="profil-title">
                                    <h3>Pièces jointes</h3>
                                </div>
                            </div>
                            <div className="bloc-right">
                                <div className="listing-div">
                                    <div className="search-button">
                                        <span> Recherche</span> <input onChange={e => this.handlePJTableChange({ filters: e.target.value })} type="search" />
                                    </div>
                                    <Table columns={this.getPJColumns()} rowKey={record => record.id}
                                        dataSource={this.state.pjs}
                                        pagination={paginationPJ}
                                        loading={loadingPJ}
                                        onChange={this.handlePJTableChange}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="bloc">
                            <div className="bloc-left">
                                <div className="profil-title">
                                    <h3>Demande de nouveaux services</h3>
                                </div>
                            </div>
                            <div className="bloc-right">
                                <div className="listing-div">
                                    <div className="search-button">
                                        <span> Recherche</span> <input onChange={e => this.handleServiceTableChange({ filters: e.target.value })} type="search" />
                                    </div>
                                    <Table columns={this.getColumnService()} rowKey={record => record.id}
                                        dataSource={this.state.demandeServices}
                                        pagination={paginationService}
                                        loading={loadingService}
                                        onChange={this.handleServiceTableChange}
                                    />
                                </div>
                            </div>

                        </div>
                     { this.state.user && this.state.user.changementAdresse === "En_cours"  &&
                     ( <div className="bloc">
                            <div className="bloc-left">
                                <div className="profil-title">
                                    <h3>Changement d'adresse</h3>
                                </div>
                            </div>
                            <div className="bloc-right">
                                <div className="listing-div">
                                    <Table columns={this.columnsChangeAdresse()} rowKey={record => record.id}
                                          dataSource={this.state.changementAdresses}
                                        // pagination={paginationService}
                                        // loading={loadingService}
                                        // onChange={this.handleServiceTableChange}
                                    />
                                </div>
                            </div>

                        </div>)}
                    </div>
                    <div className="cta-bottom-buttons" style={{ paddingTop: "20px" }}>
                        <button className="action-button" style={{ width: "20%", background: "#383495" }}> <Link style={{ color: "#fff" }} to={"/Dashboard/Edit_profil/" + this.state.user.id} >Modifier profil</Link></button>
                        {
                            (this.state.user.etat === "ACTIF" || this.state.user.etat === "STRIPE_NO_VALIDATE" || this.state.user.etat === "ADMIN_NO_VALIDATE") &&
                            <button className="action-button" onClick={e => {
                                Mutation.ChangeStateUser({ userId: this.state.user.id, etat: "ADMIN_VALIDATE" })
                                    .then(data => {
                                        message.success("Prestation validé. Un mail lui a été envoyé !");
                                        setTimeout(() => {
                                            window.location.reload(false);
                                        }, 500);
                                    })
                                    .catch(error => {
                                        message.error("Erreur de l'operation, veuillez réesseyez plus tard !");
                                    })
                            }} style={{ width: "20%", background: "#383495", cursor: "pointer" }} >Valider le compte</button>
                        }
                        {
                            (this.state.user.etat === "ACTIF" || this.state.user.etat === "STRIPE_VALIDATE" || this.state.user.etat === "ADMIN_VALIDATE") &&
                            <button className="action-button" onClick={e => this.changeStateUser} style={{ width: "20%", background: "#ff0f2e" }}  >Rejeter compte</button>
                        }
                        <button className="action-button" onClick={() => this.showConfirm()}
                            style={{ width: "20%", background: "#ff0f2e", cursor: "pointer" }}  >Supprimer compte</button>
                        <Modal title={this.state.currentDocTitle} visible={this.state.isPictureToShow} onOk={() => { this.setState({ isPictureToShow: false }) }} onCancel={() => { this.setState({ isPictureToShow: false }) }} footer={[
                            <Button key="submit" type="primary" onClick={() => { this.setState({ isPictureToShow: false }) }}>
                                OK
                    </Button>,
                        ]}>
                            {
                                this.state.currentDocType && this.state.currentDocType.toUpperCase() !== 'PDF' && this.state.currentDocType.toUpperCase() !== 'DOC' &&
                                <div className="profil-picture">
                                    <img style={{ width: "100%" }} className="img-thumbnail" src={this.state.currentDoc} onload="console.log('Logo loaded!')" />
                                </div>
                            }
                            {
                                this.state.currentDocType && (this.state.currentDocType.toUpperCase() === 'PDF' || this.state.currentDocType.toUpperCase() === 'DOC') &&
                                <div
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        height: '750px',
                                    }}
                                >
                                    <Viewer fileUrl={"https://cors-anywhere.herokuapp.com/" + this.state.currentDoc} />
                                </div>
                            }
                        </Modal>
                        <Modal title={"Validation d'un compte"} visible={this.state.validateAccount} onOk={() => { this.setState({ validateAccount: false }) }} onCancel={() => { this.setState({ isPictureToShow: false }) }} footer={[

                            <Button key="submit" type="default" onClick={() => { this.setState({ validateAccount: false }) }}>
                                Annuler
                           </Button>,
                            <Button key="submit" type="primary" onClick={() => this.changeStateUser}>
                                Oui
                           </Button>
                        ]}>
                        </Modal>

                    </div>
                </div>
            </div>
        )
    }
}

export default ProfilPro

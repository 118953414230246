import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import custom from './custom';
// const MailURL = 'https://dev-connect.heroes.swiss/';
// const MailURL = 'http://localhost:3001/';
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: custom?.apiUrl + 'admin/api'
    })
});

const getToken = () => {
    return localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
}

const setToken = (token) => {
    localStorage.setItem('token', JSON.stringify(token));
}

const getUser = () => {
    return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
}

const setUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
}

const Api = {
    client,
    MailURL: custom?.apiUrl,
    getToken,
    setToken,
    getUser,
    setUser
}

export default Api;
import React, { useState, useEffect } from 'react'
import { Menu, Dropdown, Button, message } from 'antd';
import { LoginOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import Api from '../../lib/Api';
/*
function handleButtonClick(e) {
  message.info('Click on left button.');
  console.log('click left button', e);
}
*/
const handleDeconnexion = (history) => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  history.replace('/Log-in');
}

const DashboardHeader = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(Api.getUser);
    if (!localStorage.getItem('token')) {
      if (!location.pathname.includes('/confirmation') || !location.pathname.includes('/uploads'))
        history.replace('/Log-in');
    }
    else if (localStorage.getItem('token') && window.location.pathname === '/') {
      if (!location.pathname.includes('/confirmation') || !location.pathname.includes('/uploads'))
        history.replace('/Dashboard/Accueil');
    }
  }, []);

  return (
    <div id="components-dropdown-demo-dropdown-button">
      <Dropdown overlay={
        <>
          <Menu>
            <Menu.Item key="1" icon={<UserOutlined />}>
              {user && user.email ? user.email : ''}
            </Menu.Item>
          </Menu>
          <Menu onClick={e => handleDeconnexion(history)}>
            <Menu.Item key="2" icon={<LoginOutlined />}>
              Se déconnecter
              </Menu.Item>
          </Menu>
        </>
      }>
        <Button>
          <div className="profil-pic">
            <img style={{ width: 50, borderRadius: '50%' }} src={user && user.photo && user.photo.filename ? (Api.MailURL + "uploads/" + user.photo.filename) : 'https://ultimatecourses.com/assets/author/toddmotto-1c99cf55165dc8ab6690f08a08cdcd9e0aa9a9eaff67554ea76b9462700a72b0.jpg'} />
          </div>
          {
            <div> {user && user.prenom ? user.prenom : ''} {user && user.nom ? user.nom : ''} </div>
          }
            Profil <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  )
}

export default DashboardHeader

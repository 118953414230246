import React,{Component} from 'react'
import { Table } from 'antd';
import reqwest from 'reqwest';
import { Link } from 'react-router-dom';
import Querry from '../../../lib/Querry';

const columns = [

    {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
        width: '5%',

    },

    {
      title: 'Prénom',
      dataIndex: 'prenom',
      sorter: true,
      width: '15%',
    },

    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
      width: '15%',
    },
    {
      title: 'Statut du profil',
      dataIndex: 'etat',
      sorter: true,
      width: '20%',
    },
    {
        title: 'Date de création',
        dataIndex: 'createdAt',
        render: createdAt => {
          let date = new Date(createdAt);
          return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
        },
        sorter: true,
        width: '10%',
     },
     {
        title: 'Actions',
        dataIndex: 'id',
        render: (id) => {
          return(
              <div className="cta-action-buttons">
              <button className="action-button" style={{background:"#383495",width:"40%"}}><Link style={{color:"#fff"}} to={{pathname: "/Dashboard/Profil/" + id, state: {profil: "PRESTATAIRE", typeCompte: "prestataire"}}}>Voir profil </Link></button>
              <button className="action-button"  style={{background:"#ff0f2e",width:"40%"}}> <Link style={{color:"#fff"}} to={"/Dashboard/Edit_profil/" + id}>Modifier profil</Link></button>
              {/* <button className="action-button" style={{background:"#ff0f2e",width:"40%"}}>Supprimer Pro</button> */}
              </div>
          )
        },
        width: '50%',
      },


  ];
  const getRandomuserParams = params => {
    return {
      results: params.pagination.pageSize,
      page: params.pagination.current,
      ...params,
    };
  };

class PrestataireLambda extends Component{
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 5,
    },
    loading: false,
  };
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter && sorter.field ? sorter.field : '',
      sortOrder: sorter && sorter.order ? sorter.order : '',
      pagination: {...pagination, pageSize: 5},
      filters,
    });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    Querry.GetProLamda(params.sortField ? { sortField: params.sortField + (params.sortOrder === 'ascend' ? '_ASC' : '_DESC') } : (params.pagination && params.pagination.filters ? { filters : params.pagination.filters } : {}))
    .then(data => {
      this.setState({
        loading: false,
        data: data,
        pagination: {
          ...params.pagination,
          total: data && data.length ? data.length : 0,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
    });
  };

render(){

const { data, pagination, loading } = this.state;

        return (
<>
<div className="main">
    <div className="container">

    <div className="pretataire-pro-title">
        <h2>LISTE DES PRESTATAIRES LAMBDAS INSCRITS ET VALIDÉS PAR HEROES</h2>
    </div>
    <div className="search-barre">
     <span>Recherche</span> <input type="search" onChange={e => this.handleTableChange({filters: e.target.value})}/>

    </div>

      <Table
          columns={columns}
          rowKey={record => record.id}
          dataSource={data}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
      />
          {/* <div className="cta-bottom-buttons">
              <button className="action-button" style={{width:"20%",background:"#383495"}} >Ajouter PRO</button>
              <button  className="action-button" style={{width:"20%",background:"#383495"}}  >Ajouter PRO partenaire</button>
          </div> */}
        </div>

    </div>
    </>

        )
    }

}

export default PrestataireLambda

import React, { Component } from 'react'
import { Modal, message } from 'antd';
import reqwest from 'reqwest';
import Querry from '../../../lib/Querry';
import Mutation from '../../../lib/Mutation';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const {confirm} = Modal
/*
const columns = [
    {
        title: 'Statut',
        dataIndex: 'statut',
        sorter: true,
        width: '20%'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        width: '50%',
        sorter: true,
    },
    {
        title: 'Titre',
        dataIndex: 'prestation',
        sorter: true,
        width: '20%',
        sorter: true,
        render: (prestation) => {
            return prestation.titre;
        }
    },
    {
        title: 'Date prestation',
        dataIndex: 'prestation',
        sorter: true,
        width: '20%',
        render: (prestation) => {
            let date = new Date(prestation.dateDeLaPrestation)
            return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
        },

    },
    {
        title: 'Image',
        dataIndex: 'ticketFactures',
        sorter: true,
        width: '20%',
        render: (ticketFactures) => {
            return ticketFactures.image ? ticketFactures.image.filename.split('.')[ticketFactures.image.filename.split('.').length - 1].toUpperCase() : '---'
        },
    },
    {
        title: 'Duree',
        dataIndex: 'prestation',
        sorter: true,
        width: '20%',
        sorter: true,
        render: (prestation) => {
            return prestation.dureeDeLaPrestation;
        }
    },
    {
        title: 'Format Adresse',
        dataIndex: 'adresseDestination',
        sorter: true,
        width: '20%',
        sorter: true,
        render: (adresseDestination) => {
            return adresseDestination.formattedAddress;
        }
    },
];
*/

const getRandomuserParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
    };
};

const defaultURL = 'https://connect.heroes.swiss/uploads/';

class DetailNoteDeFrais extends Component {
    state = {
        data: [],
        pagination: {
            current: 1,
            pageSize: 10,
        },
        loading: false,
        frais: null
    };

    componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
        this.noteDeFrais();
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };
    
    getDate = (dateF) => {
        let date = new Date(dateF);
        return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
    }

    getStatut = (statut) => {
        switch (statut) {
            case 'CONTESTER': return 'CONTESTÉE';
            case 'EN_COURS': return 'EN COURS';
            case 'REFUSER': return 'REFUSÉE';
            case 'VALIDER': return 'VALIDÉE';
            default: return statut;
        }
    }

    fetch = (params = {}) => {
        this.setState({ loading: true });
        reqwest({
            url: 'https://randomuser.me/api',
            method: 'get',
            type: 'json',
            data: getRandomuserParams(params),
        }).then(data => {
            this.setState({
                loading: false,
                data: data.results,
                pagination: {
                    ...params.pagination,
                    total: 200, 
                },
            });
        });
    };
    noteDeFrais() {
        const id = this.props && this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : null;
        if (id){
            Querry.DetailNoteDeFrais(id).then(frais => {
                this.setState({ frais });
            }).catch(er => console.log(er))}
    }

    showAccept = () => {
        const id = this.state.frais.id
        confirm({
            title: 'Confirmer l\'acceptation de la note de frais ?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                Mutation.ChangeStateNote({id: id, statut: "VALIDER"}).then(result => {
                    message.success("Note de frais validée !")
                    setTimeout(()=>{window.location.reload()},100)
                }).catch(er => {
                    message.error("Erreur de l'opération, veuillez réessayer plus tard !");
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    showRefuse = () => {
        const id = this.state.frais.id
        confirm({
            title: 'Confirmer le refus de la note de frais ?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                Mutation.ChangeStateNote({id: id, statut: "REFUSER"}).then(result => {
                    message.success("Note de frais refusée !")
                    setTimeout(()=>{window.location.reload()},100)
                }).catch(er => {
                    message.error("Erreur de l'opération, veuillez réessayer plus tard !");
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {
        return (
            <div className="main">
                <div className="container">
                    <h2>Détails notes de frais</h2>
                    <div className="tableau">
                        <table className="tableau-info">
                            {this.state.frais &&
                                <tbody>
                                    <tr>
                                        <td className="info-title"><b>Tickets de facture</b></td>
                                        <td className="info-data" style={{ color: "red", fontSize: "16px" }}>
                                            {
                                                this.state.frais && this.state.frais.ticketFactures && this.state.frais.ticketFactures.map(ticket => (
                                                    <div id="tickets">
                                                        <div className="assigned-profile" style={{ width: 'auto', margin: 5 }}><img src={defaultURL+ticket.image.filename} width = '150' height = '150'/></div>
                                                    </div>
                                                ))
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Statut</b></td>
                                        <td className="info-data" style={{ color: "red", fontSize: "16px" }}>{this.getStatut(this.state.frais.statut)}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Description</b></td>
                                        <td className="info-data">{this.state.frais.description}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Prix</b></td>
                                        <td className="info-data">{this.state.frais.prix}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>TVA</b></td>
                                        <td className="info-data">{this.state.frais.TVA}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Titre de la prestation</b></td>
                                        <td className="info-data">{this.state.frais.prestation && this.state.frais.prestation.titre}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Date de la  prestation</b></td>
                                        <td className="info-data">{this.getDate(this.state.frais.prestation.dateDeLaPrestation)}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Durée de la prestation</b></td>
                                        <td className="info-data">{this.state.frais.prestation && this.state.frais.prestation.dureeDeLaPrestation}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Infos prestataire</b></td>
                                        <td className="info-data"><b>Nom complet:</b> {this.state.frais.prestation.prestataire.prenom} {this.state.frais.prestation.prestataire.nom} <br/><b>email:</b> {this.state.frais.prestation.prestataire.email} <br/><b>Téléphone:</b> {this.state.frais.prestation.prestataire.phone}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Infos mandataire</b></td>
                                        <td className="info-data"><b>Nom complet:</b>{this.state.frais.prestation.mandataire.prenom} {this.state.frais.prestation.mandataire.nom} <br/><b>email:</b> {this.state.frais.prestation.mandataire.email} <br/><b>Téléphone:</b> {this.state.frais.prestation.mandataire.phone}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Adresse de destination</b></td>
                                        <td className="info-data">{this.state.frais.prestation && this.state.frais.prestation.adresseDestination && this.state.frais.prestation.adresseDestination.formattedAddress}</td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    </div>
                    {
                    this.state.frais && this.state.frais.statut != 'VALIDER' && this.state.frais.statut != 'REFUSER' &&
                    <div className="cta-bottom-buttons" style={{ paddingTop: "20px" }}>
                        <button className="action-button" onClick={this.showAccept} style={{ width: "10%", background: "#383495" }} >ACCEPTER</button>
                        <button className="action-button" onClick={this.showRefuse} style={{ width: "10%", background: "#ff0f2e" }} >REFUSER</button>
                    </div>
                    }
                </div>
            </div>

        )
    }
}

export default DetailNoteDeFrais

import Api from './Api';
import { gql } from '@apollo/client';
import { endOfMonth } from 'date-fns'
const client = Api.client;

const GetProValides = async (data) => {
  const result = await client.query({
    query: gql`
          query {
            allUsers(
              where: {
                etat_in: [ADMIN_VALIDATE, STRIPE_VALIDATE, STRIPE_NO_VALIDATE]
                typePrestataire: Professionnel
                deleted_not:  true
                roles_some: {
                  nom: PRESTATAIRE
                }
                ${data.filters ? 'OR: [{nom_contains_i: "' + data.filters + '"}{prenom_contains_i: "' + data.filters + '"}{email_contains_i: "' + data.filters + '"}{typeRegistreCommerce_contains_i: "' + data.filters + '"}]' : ''}
              }
              sortBy: ${data.sortField ? data.sortField : 'createdAt_DESC'}
            )
            {
              id
              prenom
              email
              etat
              nom
              typeRegistreCommerce
              createdAt
              photo {
                id
                filename
              }
            }
          }
         `
  });
  return result.data.allUsers;
}

const GetProLamda = async (data) => {
  const result = await client.query({
    query: gql`
        query {
          allUsers(
            where: {
              etat_in: [ADMIN_VALIDATE, STRIPE_VALIDATE, STRIPE_NO_VALIDATE]
              typePrestataire: Particulier
              deleted_not:  true
              roles_some: {
                nom: PRESTATAIRE
              }
              ${data.filters ? 'OR: [{nom_contains_i: "' + data.filters + '"}{prenom_contains_i: "' + data.filters + '"}{email_contains_i: "' + data.filters + '"}]' : ''}
            }
            sortBy: ${data.sortField ? data.sortField : 'createdAt_DESC'}
          )
          {
            id
            prenom
            email
            etat
            nom
            typeRegistreCommerce
            createdAt
            photo {
              id
              filename
            }
          }
        }
       `
  });
  return result.data.allUsers;
}

const GetPrestaAvalides = async (data) => {
  const result = await client.query({
    query: gql`
        query {
          allUsers(
            where: {
              etat_in: [ACTIF]
              deleted_not:  true
              roles_some: {
                nom: PRESTATAIRE
              }
              ${data.filters ? 'OR: [{nom_contains_i: "' + data.filters + '"}{prenom_contains_i: "' + data.filters + '"}{email_contains_i: "' + data.filters + '"}]' : ''}
            }
            sortBy: ${data.sortField ? data.sortField : 'id_ASC'}
          )
          {
            id
            prenom
            email
            etat
            nom
            typeRegistreCommerce
            createdAt
            photo {
              id
              filename
            }
          }
        }
       `
  });
  return result.data.allUsers;
}

const GetMandataires = async (data) => {
  const result = await client.query({
    query: gql`
          query {
            allUsers(
              where: {
                deleted_not:  true
                roles_some: {
                  nom: MANDATAIRE
                }
                ${data.filters ? 'OR: [{nom_contains_i: "' + data.filters + '"}{prenom_contains_i: "' + data.filters + '"}{email_contains_i: "' + data.filters + '"}]' : ''}
              }
              sortBy: ${data.sortField ? data.sortField : 'id_ASC'}
            )
            {
              id
              prenom
              email
              etat
              nom
              typeRegistreCommerce
              createdAt
              photo {
                id
                filename
              }
            }
          }
        `
  });
  return result.data.allUsers;
}

const GetServices = async (data) => {
  let serviceString= '[';
  data.serviceString && data.serviceString.forEach(service => {
    serviceString+= '"'+service + '",' ;
  })
  serviceString += ']'
  const result = await client.query({
    query: gql`
          query {
            allPrestations(
            where: {
                statut_in: [${data.statutString}]
                service: {titre_in: ${serviceString}}
                ${data.filters ? 'OR: [{titre_contains_i: "' + data.filters + '"}{dateDeLaPrestation_contains_i: "' + data.filters + '"}{totalFacturer_contains_i: "' + data.filters + '"}{reversePrestataire_contains_i: "' + data.filters + '"}]' : ''}
            }
            sortBy: ${data.sortField ? data.sortField : 'dateDeLaPrestation_DESC'}
        )   {
                id
                titre
                dateDeLaPrestation
                dateMomentCommande
                heurePrestation
                dureeDeLaPrestation
                prixDeBase
                totalFacturer
                reversePrestataire
                typeStatusPayment
                statut
                adresse {
                    id
                    formattedAddress
                    city
                }
                service {
                    id
                    titre
                }
                mandataire {
                    id
                    email
                }
                prestataire {
                    id
                    email
                }
            }
        }
       `
  });
  return result.data.allPrestations;
}


const GetMissionsToDay = async (data) => {
  const result = await client.query({
    query: gql`
          query {
            allPrestations(
            where: {
               date: "${data.date}" 
            }
            sortBy: ${data.sortField ? data.sortField : 'dateDeLaPrestation_DESC'}
        )   {
                id
                titre
                dateDeLaPrestation
                heurePrestation
                dureeDeLaPrestation
                prixDeBase
                totalFacturer
                reversePrestataire
                typeStatusPayment
                statut
                adresse {
                    id
                    formattedAddress
                }
                service {
                    id
                    titre
                }
                mandataire {
                    id
                    email
                }
                prestataire {
                    id
                    email
                }
            }
        }
       `
  });
  return result.data.allPrestations;
}


const GetMissionTomorow = async (data) => {
  const result = await client.query({
    query: gql`
          query {
            allPrestations(
            where: {
                statut_in: [
                    ${data.statutString}
                ]
                ${data.filters ? 'OR: [{titre_contains_i: "' + data.filters + '"}{dateDeLaPrestation_contains_i: "' + data.filters + '"}{totalFacturer_contains_i: "' + data.filters + '"}{reversePrestataire_contains_i: "' + data.filters + '"}]' : ''}
            }
            sortBy: ${data.sortField ? data.sortField : 'dateDeLaPrestation_DESC'}
        )   {
                id
                titre
                dateDeLaPrestation
                heurePrestation
                dureeDeLaPrestation
                prixDeBase
                totalFacturer
                reversePrestataire
                typeStatusPayment
                statut
                adresse {
                    id
                    formattedAddress
                }
                service {
                    id
                    titre
                }
                mandataire {
                    id
                    email
                }
                prestataire {
                    id
                    email
                }
            }
        }
       `
  });
  return result.data.allPrestations;
}


const GetMissionsHier = async (data) => {
  const result = await client.query({
    query: gql`
          query {
            allPrestations(
            where: {
                statut_in: [
                    ${data.statutString}
                ]
                ${data.filters ? 'OR: [{titre_contains_i: "' + data.filters + '"}{dateDeLaPrestation_contains_i: "' + data.filters + '"}{totalFacturer_contains_i: "' + data.filters + '"}{reversePrestataire_contains_i: "' + data.filters + '"}]' : ''}
            }
            sortBy: ${data.sortField ? data.sortField : 'dateDeLaPrestation_DESC'}
        )   {
                id
                titre
                dateDeLaPrestation
                heurePrestation
                dureeDeLaPrestation
                prixDeBase
                totalFacturer
                reversePrestataire
                typeStatusPayment
                statut
                adresse {
                    id
                    formattedAddress
                }
                service {
                    id
                    titre
                }
                mandataire {
                    id
                    email
                }
                prestataire {
                    id
                    email
                }
            }
        }
       `
  });
  return result.data.allPrestations;
}

const GetFactures = async (data) => {
  const result = await client.query({
    query: gql`
      query {
        allPrestations (
          where: {
            OR: [
              {
                factureMandataire_not: null
              }
              {
                facturePrestataire_not: null
              }
            ]
          }
        ) {
          id
          titre
          service {
            id
            titre
          }
          dateDeLaPrestation
          mandataire {
            id
            email
          }
          prestataire {
            id
            email
          }
          factureMandataire
          facturePrestataire
        }
      }
     `
  });
  return result.data.allPrestations;
}

const GetFacturesTermine = async (data) => {
  const result = await client.query({
    query: gql`
    query{allFactures(sortBy:dateCreation_DESC)
    {
      id
      numeroFacture
      typeFacture
      dateCreation
      fileName
      montant
      prestation{
        id
        reversePrestataire
        totalFacturer
      }
    }
    }
     `
  });
  return result.data.allFactures;
}

const GetFraisMateriels = async (data) => {
  const result = await client.query({
    query: gql`
    query{allFraisMateriels{
      id
      prix
      TVA
      prestation{
        id
        reversePrestataire
        totalFacturer
      }
    }
    }
     `
  });
  return result.data.allFactures;
}

const GetUserDetails = async (data) => {
  const result = await client.query({
    query: gql`
        query {
          User(
            where: {
              id: "${data.userId}"
            }
          ) {
            id
            nom
            prenom
            email
            isAdmin
            etat
            facebookId
            typeIdentite
            typeRegistreCommerce
            createdAt
            birthDate
            postcode
            street
            city
            country
            number
            phone
            fingerprintToken
            userStripeId
            paiementDefault
            proStripeId
            IBAN
            urlPro
            noteMoyenne
            pieceId
            newsletters
            rayon
            changementAdresse
            roles {
              id
              nom
            }
            services {
              id
              titre
              description
              model {
                id
                nom
              }
            }
            prestationPrestataires {
              id
              titre
              description
              statut
              dateDeLaPrestation
              reversePrestataire
              facturePrestataire
              notation
              mandataire {
                id
                prenom
                nom
              }
              commentaires {
                id
                contenu
              }
              service {
                id
                titre
              }
            }
            prestationMandataires {
              id
              titre
              description
              statut
              dateDeLaPrestation
              heurePrestation
              dureeDeLaPrestation
              reversePrestataire
              factureMandataire
              statut
              notation
              prestataire {
                id
                prenom
                nom
              }
              service {
                id
                titre
              }
            }
            demandeServices {
              id
              service {
                id
                titre
                description
                model {
                  id
                  nom
                }
              }
            }
            pieceJointes 
            (where : { typeCompte: ${data.typeCompte} } )
            {
              id
              type
              PJ {
                id
                filename
              } 
            }
          }
        }
       `
  });
  return result.data.User;
}

const PrestationMonthDone = async (data) => {
  let date = new Date();
  let beginDate = date;
  beginDate.setDate(1);
  beginDate.setHours(0);
  beginDate.setMinutes(0);
  beginDate.setSeconds(0);
  beginDate = beginDate.toISOString();
  let endDate = endOfMonth(date);
  endDate = endDate.toISOString();
  const result = await client.query({
    query: gql`
        query {
          allPrestations(
            where: {
              statut: Termine_Confirme
              typeStatusPayment: succeeded
              dateDeLaPrestation_gte: "${beginDate}"
              dateDeLaPrestation_lte: "${endDate}"
            }
          ) {
            totalFacturer
            reversePrestataire
            dateDeLaPrestation
          }
        }
       `
  });
  return result.data.allPrestations;
}

const PrestationStat = async (data) => {
  const result = await client.query({
    query: gql`
        query {
          allPrestations(
            where: {
              statut: Termine_Confirme
              typeStatusPayment: succeeded
            }
          ) {
            totalFacturer
            reversePrestataire
            dateDeLaPrestation
            }
        }
       `
  });
  return result.data.allPrestations;
}

const PrestationStatIndiv = async (data) => {
  const result = await client.query({
    query: gql`
    query { 
      allPrestations (
        first: 5
        where: {
          statut: Termine_Confirme
          typeStatusPayment: succeeded
        }
        sortBy:totalFacturer_DESC
      ){ 
        titre
        totalFacturer
        reversePrestataire
        dateDeLaPrestation
      }
    }
       `
  });
  return result.data.allPrestations;
}

const PrestationMonthComing = async (data) => {
  let date = new Date();
  let beginDate = date;
  beginDate.setHours(0);
  beginDate.setMinutes(0);
  beginDate.setSeconds(0);
  beginDate = beginDate.toISOString();
  let endDate = endOfMonth(date);
  endDate = endDate.toISOString();
  const result = await client.query({
    query: gql`
        query {
          allPrestations(
            where: {
              statut: En_cours
              dateDeLaPrestation_gte: "${beginDate}"
              dateDeLaPrestation_lte: "${endDate}"
            }
          ) {
            totalFacturer
            reversePrestataire
            dateDeLaPrestation
            dureeDeLaPrestation
          }
        }
       `
  });
  return result.data.allPrestations;
}

const ListNoteDeFrais = async (data) => {
  const result = await client.query({
    query: gql`
           query{
             allFraisMateriels(
              where: {AND: [
                # {statut: EN_COURS}
                {prestation : {AND:[
                  {mandataire_is_null : false}
                  {prestataire_is_null : false}
                ]}}
              ]
              ${data.filters ? 'OR: [{description_contains_i: "' + data.filters + '"}{prestation : {titre_contains_i: "' + data.filters + '"}}]' : ''}
            }
             ){
               id,
               prix,
               TVA,
               description
               statut
               ticketFactures{
                 image { filename }
               }
               prestation{
                 id
                 titre,
                 dateDeLaPrestation,
                 prestataire{
                   email
                   id
                 }
                 mandataire{ 
                   email
                   id
                 }
               }
             }
           }
      `
  });
  return result.data.allFraisMateriels;
}

const ListTempsSup = async () => {
  const result = await client.query({
    query: gql`
           query{
            allFraisTemps(
              where: {AND: [
                # {statut: EN_COURS}
                {prestation : {AND:[
                  {mandataire_is_null : false}
                  {prestataire_is_null : false}
                ]}}
              ]
            }
             ){
              id,
              prix,
      				heure
      				minute
      				reversePrestataire
               statut
               prestation{
                 id
                 titre,
                 dateDeLaPrestation,
                 prestataire{
                   email
                   id
                 }
                 mandataire{ 
                   email
                   id
                 }
               }
             }
           }
      `
  });
  return result.data.allFraisTemps;
}

const DetailNoteDeFrais = async (idNdf) => {
  const result = await client.query({
    query: gql`
    query{FraisMateriel(
      where:{  
       id : "${idNdf}"
      }){
        id
        description
        prix
        TVA
        statut
        prestation{
          id
          titre
          dateDeLaPrestation
          dureeDeLaPrestation
          reversePrestataire
          totalFacturer
          adresseDestination{
            formattedAddress
          }
          prestataire{ 
            id
            nom
            prenom
            phone
            email
          }
          mandataire{ 
            id
            nom
            prenom
            phone
            email
          }
        }
      ticketFactures{
         id
          image{
          id
          filename
        }
      }
    }
  }
      `
  });
  return result.data.FraisMateriel;
}

const DetailTempsSup = async (idNdf) => {
  const result = await client.query({
    query: gql`
    query{FraisTemp(
      where:{  
       id : "${idNdf}"
      }){
        id,
        prix,
        heure
        minute
        reversePrestataire
         statut
         prestation{
           id
           titre,
           dateDeLaPrestation,
          dureeDeLaPrestation
          reversePrestataire
          totalFacturer
          adresseDestination{
              formattedAddress
          }
          prestataire{ 
            id
            nom
            prenom
            phone
            email
          }
          mandataire{ 
            id
            nom
            prenom
            phone
            email
          }
         }
       }
  }
      `
  });
  return result.data.FraisTemp;
}

const VerifieTokenForgotPassword = async (params) => {
  const result = await client.query({
    query: gql`
        query {
          allUsers(
            where: {
              AND:[
                {tokenForgotPassword: "${params.token}"},
               {id: "${params.idUser}"}
              ]
              
            }
          )
          {
            id
            tokenForgotPassword
            email
            expireAtCodeForgotPassword
          }
        }
       `
  });
  return result.data.allUsers;
}

const GETAVISRECUS = async (data) => {
  if (data.sortField === 'email_ASC' || data.sortField === 'email_DESC') {
    const result = await client.query({
      query: gql`
              query{
                allAvis(
                  ${data.filters ?
          ' where:{ OR: [{content_contains_i: "' + data.filters + '"}{dateCreation_contains_i: "' + data.filters + '"}]   }'
          :
          ''
        }
                  sortBy:  dateCreation_DESC
                ){
                  id,
                  content,
                  dateCreation,
                  utilisateur
                  {
                    email,
                    id,
                    nom,
                    prenom
                  }
                }
              }
          `
    });
    return result.data.allAvis;
  }
  const result = await client.query({
    query: gql`
            query{
              allAvis(
              
                  ${data.filters ?
        ' where:{ OR: [{content_contains_i: "' + data.filters + '"}{dateCreation_contains_i: "' + data.filters + '"}]   }'
        :
        ''
      }
              
                sortBy: ${data.sortField && (data.sortField !== 'email_ASC' || data.sortField !== 'email_DESC') ? data.sortField : 'dateCreation_DESC'}

              ){
                id,
                content,
                dateCreation,
                utilisateur{
                  email,
                  id,
                  nom,
                  prenom
                }
              }
            }
        `
  });
  return result.data.allAvis;

}

const GETPRESTATIONBYID = async (idMission) => {
  await client.cache.reset();
  const result = await client.query({
    query: gql`
        query
        { 
        Prestation(
          where:{  
           id : "${idMission}"
          }){
          id,
          titre,
          dateDeLaPrestation,
          heurePrestation,
          totalFacturer,
          statut,
          reversePrestataire
          dateMomentCommande,
          heureMomentCommande
          description
          dureeDeLaPrestation,
          distanceParKm,
          typeStatusPayment
          dateAnnulation
          adresse{
            formattedAddress
          }
          service {
            titre
            model {
              nom
            }
          },
          choix{
            categorie { id ,nom }
          }
          mandataireDemandeChangementHoraire{ 
            id,
            nouvelleDate,
            nouvelleHeure,
            statut,
            totalFacturer,
            reversePrestataire
          }
         demandeChangementHoraires {
            id,
            nouvelleDate,
            nouvelleHeure,
            statut,
            reversePrestataire
            totalFacturer
          }
          acceptationPrestations{
            id
            statut
            prestataire{
              nom,
              email,
              id,
              prenom,
              noteMoyenne
            }
          }
          prestataire {
            id,
            nom,
            prenom,
            noteMoyenne,
            email
          }
          fraisMateriels{
            id,
            description,
            prix,
            TVA,
            statut
          }
          fraisTemps{
            id,
            prix,
            statut
            heure,
            minute,
            reversePrestataire
          }
          
          mandataire {
            id,
            nom,
            email,
            prenom,
          }
        }
        }
         `
  });
  return result.data.Prestation;
}

const GetTitreServices = async (data) => {
  const result = await client.query({
    query: gql`
    query{
      allServices{
        titre
        categories{
          nom
        }
      }
    }
    `
  });
  return result.data.allServices;
}

const GetTitreCategories = async (data) => {
  const result = await client.query({
    query: gql`
    query{
      allServices (where: {titre: "${data.service}"}) {
        titre
        categories{
          nom
        }
      }
    }
    `
  });
  return result.data.allServices;
}

const GetPrestationByService= async (service) => {
  const result = await client.query({
    query: gql`
    query{allPrestations(
      where:  { 
        statut: Termine_Confirme
        typeStatusPayment: succeeded
        service: {titre: "${service}"}
     } 
    ){
      id
      titre
      totalFacturer
      reversePrestataire
      service{ 
        titre
        categories{ 
          nom
      }
    }
    }
}`
  });
  return result.data.allPrestations;
}

const GetPrestationByPrestataire = async (id) => {
  const result = await client.query({
    query: gql`
    query{ 
      allAcceptationPrestations 
      (where: {prestataire: {id: "${id}"}})
      { 
        prestation{
          titre 
          totalFacturer
          reversePrestataire
        }
        prestataire{
          id
          prenom
          nom
        }
      }
    }`
  });
  return result.data.allAcceptationPrestations;
}

const GetAllUsers = async (data) => {
  const result = await client.query({
    query: gql`
    query{
      allUsers{
        id
        prenom
        nom
        email
        phone
      }
    }`
  });
  return result.data.allUsers;
}

const   GetPrestatairesByNotes = async (data) => {
  const result = await client.query({
    query: gql`
    query{
      allUsers(
        sortBy: noteMoyenne_DESC
        first: 5
      ){
           id
        prenom
        nom
        noteMoyenne
        }
    }`
  });
  return result.data.allUsers;
}

const GetAllMissions = async (data) => {
  const result = await client.query({
    query: gql`
    query{allPrestations{
      id
      titre
      statut
      totalFacturer
      reversePrestataire
      }
    }
     `
  });
  return result.data.allPrestations;
}

const GetAllPrestations = async (data) => {
  const result = await client.query({
    query: data.to ? gql`
    query{allPrestations(
      where:  { 
        dateDeLaPrestation_gt: "${data.from}",
        dateDeLaPrestation_lte: "${data.to}"
     } 
    ){
      id
      titre
      statut
      dateDeLaPrestation
      totalFacturer
      reversePrestataire
      dateAnnulation
      adresse{
        id
        formattedAddress
      }
      mandataire{
        id
        prenom
        nom
        email
      }
      prestataire{
        id
        prenom
        nom
        email
      }
    }}
     `: 
     gql`
    query{allPrestations{
      id
      titre
      statut
      dateDeLaPrestation
      totalFacturer
      reversePrestataire
      adresse{
        id
        formattedAddress
      }
      mandataire{
        id
        prenom
        nom
        email
      }
      prestataire{
        id
        prenom
        nom
        email
      }
    }}
     `
  });
  return result.data.allPrestations;
}

const GetPrestationsByMandataire = async (data) => {
  const result = await client.query({
    query: gql`
    query{allPrestations{
      id
      titre
      totalFacturer
      reversePrestataire
      mandataire{
        id
        prenom
        nom
        email
      }
    }
  }`
  });
  return result.data.allPrestations;
}



const Querry = {
  GetProValides,
  GetProLamda,
  GetPrestaAvalides,
  GetUserDetails,
  GetMandataires,
  PrestationMonthDone,
  PrestationMonthComing,
  VerifieTokenForgotPassword,
  GETAVISRECUS,
  GetServices,
  GetFactures,
  ListNoteDeFrais,
  ListTempsSup,
  GETPRESTATIONBYID,
  GetMissionsToDay,
  GetMissionTomorow,
  GetMissionsHier,
  GetFacturesTermine,
  GetFraisMateriels,
  DetailNoteDeFrais,
  DetailTempsSup,
  GetAllPrestations,
  PrestationStat,
  PrestationStatIndiv,
  GetPrestationByService,
  GetTitreServices,
  GetTitreCategories,
  GetPrestatairesByNotes,
  GetPrestationByPrestataire,
  GetAllUsers,
  GetPrestationsByMandataire,
  GetAllMissions
}

export default Querry;
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Form, Input, Button, Checkbox, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import splash from '../../../assets/images/splash.png';
import Mutation from '../../../lib/Mutation';
import Api from '../../../lib/Api';
import { includes } from 'lodash';

const LoginForm = (props) => {

  const initialValues = {
    email: "",
    password: ""
  }
  const history = useHistory();
  const [login, setLogin] = useState(initialValues);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('/confirmation') || location.pathname.includes('/uploads')) {
      // history.replace('/Log-in');
      return;
    } else
      if (localStorage.getItem('token')) {
        history.replace('/Dashboard/Accueil');
      }
  }, [])

  const handleConnection = () => {
    Mutation.Login(login).then((login) => {
      if (login && login.authenticateUserWithPassword) {
        Api.setToken(login.authenticateUserWithPassword.token);
        Api.setUser(login.authenticateUserWithPassword.item);
        if (!location.pathname.includes('/confirmation') && !location.pathname.includes('/uploads'))
          history.replace('/Dashboard/Accueil');
      }
      else {
        message.error('Email ou mot de passe incorrect !');
        setLogin({ ...login, password: '' });
      }
    })
      .catch((error) => {
        message.error('Email ou mot de passe incorrect !');
        setLogin({ ...login, password: '' });
      })
  }
  
  const redirectForgotPassword = () => {
    console.log("redirect password")
    alert("En cours de developpement")
  }

  return (
    <div className="main">
      <div className="container">
        <div className="formulaire-center">
          <div className="header-cont">
            <img style={{ width: '50%' }} src={splash} alt="splach" />
          </div>
          <div className="login-form-content">
            <Form
              name="normal_login"
              className="login-form"
              initialValues={initialValues}
              onFinish={props.onFinish}
              onFinishFailed={props.onFinishFailed}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Veuillez entrer votre nom d'utilisateur",
                  },
                ]}
              >
                <Input value={login.email} onChange={e => setLogin({ ...login, email: e.target.value })} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Veuillez entrer votre mot de passe',
                  },
                ]}
              >
                <Input
                  value={login.password}
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  onChange={e => setLogin({ ...login, password: e.target.value })}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                {/* <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Se souvenir de moi</Checkbox>
        </Form.Item> */}

                <a className="login-form-forgot" style={{ textDecorationLine: "underline", justifyContent: 'center', marginRight: '80px' }} onClick={redirectForgotPassword}>
                  Mot de passe oublié?
                </a>
              </Form.Item>

              <Form.Item>
                <div className="se-connecter">
                  <Button onClick={e => handleConnection()} type="primary" htmlType="submit" className="login-form-button"  >
                    Se connecter
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>

        </div>

      </div>
    </div>

  )
}

export default LoginForm

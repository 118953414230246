import React, { Component } from 'react'
import { Table } from 'antd';
import reqwest from 'reqwest';
import { Link } from 'react-router-dom';
import Querry from '../../../lib/Querry';


const columns = [

  {
    title: 'Description',
    dataIndex: 'description',
    sorter: true,
    width: '20%',
  },


  {
    title: 'Prix',
    dataIndex: 'prix',
    sorter: true,
    width: '15%',
  },


  {
    title: 'TVA en %',
    dataIndex: 'TVA',
    sorter: true,
    width: '15%',
  },

  {
    title: 'Mission',
    dataIndex: 'prestation',
    sorter: true,
    render: (prestation) => {
      return (
        <div>
          <a key={prestation.id}><Link to={"/Dashboard/Mandataires/"+prestation.id}>{prestation.titre}</Link></a>
        </div>
      )
    },
    width: '15%',
  },
  {
    title: 'Prestataire',
    dataIndex: 'prestation',
    sorter: true,
    render: (prestation) => {
      return (
        <div>
          <a style={{  width: "40%" }} key={prestation.id}><Link to={{pathname: "/Dashboard/Profil/" + prestation.prestataire && prestation.prestataire ? prestation.prestataire.id : "", state: {profil: "PRESTATAIRE", typeCompte: "prestataire"} }}>{prestation.prestataire.email}</Link></a>
        </div>
      )
    },
    width: '15%',
  },
  {
    title: 'Mandataire',
    dataIndex: 'prestation',
    sorter: true,
    render: (prestation) => {
      return (
        <div>
          <a style={{  width: "40%" }} key={prestation.id}><Link to={{pathname: "/Dashboard/Profil/" + prestation.mandataire && prestation.mandataire ? prestation.mandataire.id : "", state: {profil: "MANDATAIRE", typeCompte: "mandataire"} }}>{prestation.mandataire.email}</Link></a>
        </div>
      )
    },
    width: '15%',
  },
  {
    title: 'Statut',
    dataIndex: 'statut',
    render: (statut) => {
      switch (statut) {
        case 'CONTESTER': return 'CONTESTÉE';
        case 'EN_COURS': return 'EN COURS';
        case 'REFUSER': return 'REFUSÉE';
        case 'VALIDER': return 'VALIDÉE';
        default: return statut;
      }
    },
    sorter: true,
    width: '10%',
  },


  {
    title: 'Actions',
    dataIndex: 'id',
    sorter: true,
    render: (id) => {
      return (
        <div className="cta-action-buttons">
          <button className="action-button" style={{ background: "#383495", width: "100%" }}><Link to={"/Dashboard/Note_de_frais/"+ id}>Détails</Link></button>
        </div>
      )
    },
    width: '40%',
  },


];

const getRandomuserParams = params => {
  return {
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  };
};

class NoteDeFrais extends Component {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  };
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  getNoteDeFrais = (params) => {
    Querry.ListNoteDeFrais(params.sortField ? { sortField: params.sortField + (params.sortOrder === 'ascend' ? '_ASC' : '_DESC') } : (params.pagination && params.pagination.filters ? { filters : params.pagination.filters } : {}))
    .then(results => {
      this.setState({
        loading: false,
        data: results,
        pagination: {
          ...params.pagination,
          total: results && results.length ? results.length : 0,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
    }).catch(er => console.log(er))
  }
  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter && sorter.field ? sorter.field : '',
      sortOrder: sorter && sorter.order ? sorter.order : '',
      pagination: {...pagination, pageSize: 7},
      filters,
    });
  };
  fetch = (params = {}) => {
    this.setState({ loading: true })
    this.getNoteDeFrais(params);
  };

  render() {

    const { data, pagination, loading } = this.state;

    return (
      <>
        <div className="main">
          <div className="container">
              <h2>Notes de frais</h2>
            <div className="search-barre">
              <span>Recherche : </span> <input type="search" placeholder="    par ''description'' ''Mission''" onChange={e => this.handleTableChange({filters: e.target.value})}/>

            </div>

            <Table
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
            />
            <div className="cta-bottom-buttons" style={{ paddingTop: "20px" }}>
              <button className="action-button" style={{ width: "20%", background: "#383495" }} >PDF</button>
              <button className="action-button" style={{ width: "20%", background: "#383495" }}  >XLS</button>

            </div>
          </div>

        </div>
      </>

    )
  }

}

export default NoteDeFrais




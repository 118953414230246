import React, { useState, useEffect } from 'react';
import Querry from '../../../lib/Querry';
import { Statistic, Card, DatePicker, Timeline, Space, TimePicker, Select } from 'antd'
import moment from 'moment';
import PieChart from './PieChart';
import BarChart from './BarChart';
import { Container, Row, Col } from 'react-grid-system';
const _ = require('lodash');

const { Option } = Select;
const { RangePicker } = DatePicker;

//const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30; // Moment is also OK

function onSearch(val) {
  console.log('search:', val);
}

function formatDate(dat) {
    let date = new Date(dat)
    return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
}

function PickerWithType({ type, onChange }) {
  if (type === 'time') return <TimePicker onChange={onChange} />;
  if (type === 'date') return <DatePicker onChange={onChange} />;
  return <DatePicker picker={type} onChange={onChange} />;
}
  
/*function SwitchablePicker() {
  const [type, setType] = useState('date');
  return (
    <Space>
      <Select value={type} onChange={setType}>
        <Option value="date">Date</Option>
        <Option value="month">Month</Option>
        <Option value="year">Year</Option>
      </Select>
      <PickerWithType type={type} onChange={value => console.log(value)} />
    </Space>
  );
}*/

function sortServices(services = []) {
  let tab = [];
  services.map((item) => item && item.map((result) => result && tab.push(result)))
  let sorted = _.sortBy(tab, [function (o) { return o.montant; }]).reverse();
  return sorted;
}

function groupByCat(services = []) {
  let byCat = 0, tab = [];
  let categories = _.groupBy(services, 'titre');
    _.forEach(categories, function(value, key) {
      byCat = _.sumBy(value, function(o) { return o.totalFacturer-o.reversePrestataire; })
      tab.push({titre:key, montant:byCat})
     });
  return  _.sortBy(tab, [function(o) { return o.montant; }]).reverse();
}
  
const Statistique = () => {

  const [TF, setTF] = useState(0);
  const [RP, setRP] = useState(0);
  const [CA, setCA] = useState(0);
  const [mt, setMt] = useState(0);
  const [nbrMissions, setNbrMissions] = useState([]);
  const [nbrMissionsServ, setNbrMissionsServ] = useState([]);
  const [nbrMissionsPeriode, setNbrMissionsPeriode] = useState([]);
  const [CAPeriode, setCAPeriode] = useState(0);
  const [date, setDate] = useState(0);
  const [mj, setMj] = useState([]);
  const [getService, setGetService] = useState([]);
  const [noteClient, setNoteClient] = useState([]);
  const [servDetail, setServDetail] = useState([]);
  const [allCAP, setAllCAP] = useState([]);
  const [titreServ, setTitreServ] = useState("<--* Choisir un service");
  const [allUsers, setAllUsers] = useState("");
  const [from, setFrom] = useState("<Date Début>");
  const [to, setTo] = useState("<Date fin>");
  const [mClients, setMClients] = useState([]);


  function handleChange (dates, dateStrings){
    if (dates && dateStrings) {
      setTo(dateStrings[1]);
      setFrom(dateStrings[0]);
      Querry.GetAllPrestations({'to':dateStrings[1], 'from': dateStrings[0]}).then(data => {
        let mt = 0, counter = 0;
        data.forEach(prestation => {
          prestation.totalFacturer && prestation.reversePrestataire ? mt += prestation.totalFacturer - prestation.reversePrestataire : mt += 0;
          counter++;
        });
        setNbrMissionsPeriode(counter);
        setCAPeriode(mt);
      }).catch(e => { console.log(e) })
  
    } else {
      setNbrMissionsPeriode(0);
      setCAPeriode(0);
      setTo("<Date fin>");
      setFrom("<Date Début>");
    };
  }

  function onChange(value) {
    console.log(value);
    Querry.GetPrestationByService(value)
      .then(data => {
        let mt = 0;
        data.forEach(prestation => {
          prestation.totalFacturer && prestation.reversePrestataire ? mt += prestation.totalFacturer - prestation.reversePrestataire : mt += 0;
        });
        setMt(mt);
        setTitreServ(value);
        setNbrMissionsServ(data);
      }).catch(e => { console.log(e) });
  }

  async function CAPrestataire(allUsers, prestataire) {
    var mt = 0, nombre = 0;
    const data = await Querry.GetPrestationByPrestataire(prestataire)
    data.forEach(p => {p && p.prestation && p.prestation.totalFacturer && p.prestation.reversePrestataire ? 
    mt += p.prestation.totalFacturer - p.prestation.reversePrestataire : mt += 0;
    nombre++;
    });
    const user = allUsers ? allUsers.find(user=> (user.id == prestataire)) : {};
    return [{ 'ID': user.id, 'prenom':user.prenom, 'nom':user.nom, 'montant': mt, 'nombre': nombre }];
  }

  const AllCAPrestataire = async (data) => {
    var tab = [];
    for(let i=0; i < (data.length); i++) {
      if (data && data[i] && data[i].id) {
        var CAP =  await CAPrestataire(data, data[i].id);
        //console.log("CAP For : ", CAP)
        tab.push(CAP)
      }
    }
    setAllCAP(tab);
  }

  function calculCAService(titre) {
    var mt = 0, i = 0;
    let tab = [];
    Querry.GetPrestationByService(titre)
      .then(data => {
        data.forEach(prestation => {
          prestation.totalFacturer && prestation.reversePrestataire ?
            mt += prestation.totalFacturer - prestation.reversePrestataire :
            mt += 0;
        });
        tab.push({ 'titre': titre, 'montant': mt });
      }).catch(e => { console.log(e) });
    return tab;
  }

  function detailService(allServices) {
    var tab = [];
    allServices && allServices.forEach(service => {
      var services = calculCAService(service.titre);
      tab.push(services)
    })
    setServDetail(tab);
  }

  useEffect(() => {
    Querry.PrestationStat().then(data => {
      let CA = 0, RP = 0, TF = 0;
      data.forEach(prestation => {
        prestation.totalFacturer && prestation.reversePrestataire ? CA += prestation.totalFacturer - prestation.reversePrestataire : CA += 0;
        prestation.totalFacturer ? TF += prestation.totalFacturer : TF += 0;
        prestation.reversePrestataire ? RP += prestation.reversePrestataire : RP += 0;
      });
      setCA(CA);
      setTF(TF);
      setRP(RP);
      setNbrMissions(data);
    }).catch(e => { console.log(e) })

    Querry.PrestationStatIndiv().then(data => {
      data = _.sortBy(data, (ele) => ele.totalFacturer - ele.reversePrestataire).reverse();
      setMj(data);
    }).catch(e => { console.log(e) })

    Querry.GetPrestatairesByNotes().then(data => {
      setNoteClient(data);
    }).catch(e => { console.log(e) })

    Querry.GetTitreServices().then(data => {
      setGetService(data);
      detailService(data);
    }).catch(e => { console.log(e) })

    Querry.GetAllUsers().then(data => {
      setAllUsers(data);
      AllCAPrestataire(data);
    }).catch(e => { console.log(e) })

    Querry.GetPrestationsByMandataire().then(data => {
      let datas = _.map(_.countBy(data, "mandataire.prenom"), (val, key) => ({ mandataire: key, total: val }))
      //let datas = _.map(_.groupBy(data, "mandataire.id"), (val, key) => ({ mandataire: key, total: val }))
      let sorted = _.sortBy(datas, function(o){return o.total;}).reverse();
      setMClients(sorted)
    }).catch(e => { console.log(e) })

  }, []);

return (
  <div className="main">
    <div className="container">
    <div className="title"><h2>Statistiques périodiques HEROES</h2></div>
      <div className="search-barre">
        <span><b>Période:     </b></span>
        <Space direction="vertical" size={12}>
          <RangePicker
            ranges={{
              Today: [moment(), moment()],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
            }}
          //  onChange={e => this.handleTableChange({filters: e.target.value})} <div className="search-barre"><SwitchablePicker /></div>
           onChange={handleChange}
          />
        </Space>
      </div>
      <div className="bloc-stat">
        <Container>
            <Row>
            <Col sm={4}>
              <Card>
               <h3>Résultat global LIVE : Sur <b>{nbrMissions.length}</b> missions en cours</h3><br/><hr/><br/>
                  <Statistic title="Chiffre d'affaires global actuel (CHF)" value={TF.toFixed(2)} valueStyle={{ color: '#3f8600' }}/>
                  <Statistic title="Montant reversé aux prestataires (CHF)" value={RP.toFixed(2)} valueStyle={{ color: '#3f8600' }}/>
                </Card>
              </Col>
              <Col sm={4}>
                <Card>
               <h3>Résultat Heroes global LIVE (Marge brute)</h3><br/><hr/><br/>
                  <Statistic title="Nombre total de missions" value={nbrMissions.length} valueStyle={{ color: '#3f8600' }}/>
                  <Statistic title="Chiffre d'affaires global actuel (CHF)" value={CA.toFixed(2)} valueStyle={{ color: '#cf1322' }}/>
                </Card>
              </Col>
            <Col sm={4}>
              <Card>
                <h3>Résultat Heroes par période: {from + ' / ' + to}</h3><hr /><br />
                <Statistic title="Nombre total de missions" value={nbrMissionsPeriode} valueStyle={{ color: '#3f8600' }} />
                <Statistic title="CA global de la période (CHF)" value={CAPeriode.toFixed(2)} valueStyle={{ color: '#cf1322' }} />
                </Card>
              </Col>
            </Row><br/>
            <Row>
              <Col sm={4}>
                <Card>
               <h3>Résultat par service:
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Choisir le service"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >{getService &&  getService.map(serv => (
                <Option value={serv.titre}>{serv.titre}</Option>
                ))
                }
                </Select></h3><br/><hr/><br/>
                  <Statistic title="Nombre total de missions" value={nbrMissionsServ.length} valueStyle={{ color: '#3f8600' }}/>
                  <Statistic title="Chiffre d'affaires global (CHF)" value={mt.toFixed(2)} valueStyle={{ color: '#cf1322' }}/>
                </Card>
              </Col>
              <Col sm={4}>
                <Card>
              <h3>Sous-services de: <b>{titreServ}</b></h3><hr/><br/>
                  <Timeline mode={'left'}>{nbrMissionsServ && groupByCat(nbrMissionsServ).map((detail, index) => (index < 5 &&
                    <Timeline.Item label={detail.titre}>{detail.montant && detail.montant.toFixed(2) } CHF</Timeline.Item>
                  ))}</Timeline>
                </Card>
              </Col>
              <Col sm={4}>
                <Card>
                  <h3>Les 5 meilleurs services en CA Heroes</h3><hr/><br/>
                  <Timeline mode={'left'}>
                    {servDetail && (sortServices(servDetail)).map((detail, index) => index < 5 &&
                        <Timeline.Item label={detail.titre}>{detail && (detail.montant).toFixed(2)} CHF</Timeline.Item>
                      )
                  }</Timeline>
                </Card>
              </Col>
            </Row><br/>
            <Row>
              <Col sm={4}>
                <Card>
                <h3>Les 5 meilleures journées en CA Heroes</h3><hr/><br/>
                  <Timeline mode={'left'}>{mj && mj.map((detail) => (
                    <Timeline.Item label={formatDate(detail.dateDeLaPrestation)}>{ (detail.totalFacturer - detail.reversePrestataire).toFixed(2) } CHF</Timeline.Item>
                  ))}</Timeline> 
                </Card>
              </Col>
              <Col sm={4}>
                <Card>
                <h3>Les 5 meilleurs prestataires par mission acceptée</h3><hr/><br/>
                <Timeline mode={'left'}>
                    {allCAP && (sortServices(allCAP)).map((detail, index) => index < 5 &&
                        <Timeline.Item label={detail.prenom+" "+detail.nom}>{detail.nombre}</Timeline.Item>
                      )
                  }</Timeline>
                </Card>
              </Col>
              <Col sm={4}>
                <Card>
                <h3>Les 5 meilleurs prestataires par CA engendré</h3><hr/><br/>
                <Timeline mode={'left'}>
                    {allCAP && (sortServices(allCAP)).map((detail, index) => index < 5 &&
                        <Timeline.Item label={detail.prenom+" "+detail.nom}>{detail && (detail.montant).toFixed(2)} CHF</Timeline.Item>
                      )
                  }</Timeline>
                </Card>
              </Col>

            </Row><br/>
            <Row>
              <Col sm={4}>
                <Card> 
                <h3>Les 5 meilleurs clients par mission engendrée</h3><hr/><br/>
                <Timeline mode={'left'}>
                    {mClients && mClients.map((detail, index) => index < 5 &&
                        <Timeline.Item label={detail.mandataire}>{detail.total}</Timeline.Item>
                      )
                  }</Timeline>
                </Card>
              </Col>

              <Col sm={4}>
                <Card>
                <h3>Les 5 meilleurs clients par note</h3><hr/><br/>
                <Timeline mode={'left'}>{noteClient && noteClient.map(detail => (
                    <Timeline.Item label={detail.prenom+" "+detail.nom}>{detail.noteMoyenne}</Timeline.Item>
                  ))}</Timeline>
                </Card>
              </Col>
            </Row><br/>

            <hr/><br/><h2>Représentation graphique</h2><br/><hr/><br/>

          <Row>
            <Col sm={6}>
              <Card>
                <h3>Diagramme en barre des différents états des missions</h3><hr /><br />
                <BarChart />
              </Card>
            </Col>
            <Col sm={6}>
            <Card>
                <h3>Diagramme circulaire des différents états des missions</h3><hr /><br />
                <PieChart />
              </Card>
            </Col>
          </Row><br/>
        </Container>
        
      </div>
    </div>
  </div>
)
}

export default Statistique

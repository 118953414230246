import Api from './Api';
import { gql } from '@apollo/client';
const client = Api.client;

const Login = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                authenticateUserWithPassword(
                    email: "${data.email}"
                    password: "${data.password}"
                )
                {
                    token
                    item {
                        id
                        prenom
                        nom
                        email
                        roles{
                            nom
                        }
                        photo {
                            id
                            filename
                        }
                    }
                }
            }
        `
    }
    );
    return result.data;
}

const DeleteDemandeService = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                deleteDemandeService (
                    id: "${data.serviceId}"
                ) {
                    id
                }
            }
        `
    }
    );
    return result.data;
}

const ConnectService = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                updateUser (
                    id: "${data.userId}"
                    data: {
                        services: {
                            connect: {
                                id: "${data.serviceId}"
                            }
                        }
                    }
                ) {
                    id
                }
            }
        `
    }
    );
    return result.data;
}

const DisconnectService = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                updateUser (
                    id: "${data.userId}"
                    data: {
                        services: {
                            disconnect: {
                                id: "${data.serviceId}"
                            }
                        }
                    }
                ) {
                    id
                }
            }
        `
    }
    );
    return result.data;
}

const ChangeStateUser = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                updateUser(
                id: "${data.userId}"
                data: {
                    etat: ${data.etat}
                }
                )
            {
                id
                etat
            }
            }
        `
    }
    );
    return result.data;
}


const DeletedAccount = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                deleteAccount(
                id: "${data.userId}"
                profil: "${data.profil}"
                )
            }
        `
    }
    );
    return result.data;
}

const UpdateUser = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                updateUser(
                    id: "${data.id}"
                    data: {
                        prenom: "${data.prenom}"
                        nom: "${data.nom}"
                        birthDate: "${data.birthDate}"
                        street: "${data.street}"
                        city: "${data.city}"
                        postcode: ${data.postcode}
                        number: ${data.number}
                        phone: "${data.phone}"
                    }
                )
                {
                    id
                }
            }
        `
    }
    );
    return result.data;
}

const ChangePassword = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                updateUser(
                    id: "${data.id}"
                    data: {
                        password: "${data.password}"
                    }
                )
                {
                    id
                }
            }
        `
    }
    );
    return result.data;
}
const ValiderChangementAdresse = async (idUser) => {
    const mutation = gql`
       mutation{
        updateUser(
               id: "${idUser}",
               data:{
                   changementAdresse: Valider
               }
           ){
               id
           }
       }
     `
    const result = client.mutate({ mutation });
    return result.data.updateUser;
}

const deleteAvis = async (id) => {
    const result = await client.mutate({
        mutation: gql`
       mutation{
            deleteAvi(
                id: "${id}"
            )
        {
            id
        }
    }
        `
    }
    );
    return result.data;
}

const deleteFactures = async (id) => {
    const result = await client.mutate({
        mutation: gql`
       mutation{
            deleteFacture(
                id: "${id}"
            )
        {
            id
        }
    }
        `
    }
    );
    return result.data;
}

const ChangeStateTempSup = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                updateFraisTemp(
                id: "${data.id}"
                data: {
                    statut: ${data.statut}
                }
                )
            {
                id
                statut
            }
            }
        `
    }
    );
    return result.data;
}

const ChangeStateNote = async (data) => {
    const result = await client.mutate({
        mutation: gql`
            mutation {
                updateFraisMateriel(
                id: "${data.id}"
                data: {
                    statut: ${data.statut}
                }
                )
            {
                id
                statut
            }
            }
        `
    }
    );
    return result.data;
}

const Mutation = {
    Login,
    ConnectService,
    DisconnectService,
    DeleteDemandeService,
    ChangeStateUser,
    UpdateUser,
    ChangePassword,
    DeletedAccount,
    ValiderChangementAdresse,
    deleteAvis,
    deleteFactures,
    ChangeStateTempSup,
    ChangeStateNote
}

export default Mutation;
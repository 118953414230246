import React,{Component} from 'react'
import { Table } from 'antd';
//import reqwest from 'reqwest';
import { Link } from 'react-router-dom';
import Querry from '../../../lib/Querry';
import {CSVLink, CSVDownload} from 'react-csv';
import jsPDF from "jspdf";
import "jspdf-autotable";

const columns = [

    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
      width: '20%',
    },


    {
      title: 'Prénom',
      dataIndex: 'prenom',
      sorter: true,
      width: '20%',
    },


    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      width: '10%',
    },


    {
        title: 'Etat',
        dataIndex: 'etat',
        sorter: true,
        width: '15%',

      },

     {
        title: 'Actions',
        dataIndex: 'id',
        sorter: true,
        render: (id) => {
return(
    <div className="cta-action-buttons">
    <Link style={{color:"#fff", cursor: "pointer"}} to={{pathname:"/Dashboard/Profil/" + id, state: {profil: "MANDATAIRE", typeCompte: "mandataire"}}}><button className="action-button" style={{background:"#383495",width:"40%"}}>Voir profil</button></Link>
    <Link style={{color:"#fff", cursor: "pointer"}} to={"/Dashboard/Edit_profil/" + id}><button className="action-button"  style={{background:"#ff0f2e",width:"40%"}}>Modifier profil</button></Link>
    </div>
)
        },
        width: '40%',
      },


  ];
  const getRandomuserParams = params => {
    return {
      results: params.pagination.pageSize,
      page: params.pagination.current,
      ...params,
    };
  };

class Mandataire extends Component{
    state = {
        data: [],
        csvData: [],
        pagination: {
          current: 1,
          pageSize: 7,
        },
        loading: false,
      };
      componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
      }
      handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
          sortField: sorter && sorter.field ? sorter.field : '',
          sortOrder: sorter && sorter.order ? sorter.order : '',
          pagination: {...pagination, pageSize: 7},
          filters,
        });
      };

      fetch = (params = {}) => {
        this.setState({ loading: true });
        Querry.GetMandataires(params.sortField ? { sortField: params.sortField + (params.sortOrder === 'ascend' ? '_ASC' : '_DESC') } : (params.pagination && params.pagination.filters ? { filters : params.pagination.filters } : {}))
        .then(data => {
          this.createCSVData(data);
          this.setState({
            loading: false,
            data: data,
            pagination: {
              ...params.pagination,
              total: data && data.length ? data.length : 0,
              // 200 is mock data, you should read it from server
              // total: data.totalCount,
            },
          });
        });
      };

      createCSVData(data) {
        let csvData = [
          ['Nom', 'Prénom', 'Email', 'Etat']
        ];
        data.forEach(element => {
          csvData.push(
            [element.nom, element.prenom, element.email, element.etat]
          )
        });
        this.setState({ ...this.state, csvData})
      }

      exportPDF() {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Liste des mandataires";
        const headers = [["NOM", "PRENOM", "EMAIL", "ETAT"]];

        const data = this.state.data.map(elt=> [elt.nom, elt.prenom, elt.email, elt.etat]);

        let content = {
          startY: 50,
          head: headers,
          body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("mandataires" + new Date() + ".pdf");
      }

    render(){

    const { data, pagination, loading } = this.state;

        return (
<>
<div className="main">
    <div className="container">

        <h2>Liste des clients</h2>
    <div className="search-barre">
     <span>Recherche</span> <input type="search" onChange={e => this.handleTableChange({filters: e.target.value})}/>

    </div>

    <Table
        columns={columns}
        rowKey={record => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={this.handleTableChange}
    />
        <div className="cta-bottom-buttons" style={{paddingTop:"20px"}}>
          <button className="action-button" onClick={() => this.exportPDF()} style={{width:"20%",background:"#383495"}} >PDF</button>
          <CSVLink data={this.state.csvData} ><button  className="action-button" style={{width:"20%",background:"#383495"}}  >CSV</button></CSVLink>

      </div>
    </div>

</div>
</>

        )
    }

}

export default Mandataire

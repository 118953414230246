import React, { useEffect, useState } from 'react'
import Querry from '../../../lib/Querry';
import Mutation from '../../../lib/Mutation';
import { message } from 'antd';

const EditProfil = ({ match }) => {

    const [ user, setUser ] = useState({});

    useEffect(() => {
        Querry.GetUserDetails({ userId: match.params.id})
            .then(data => {
                setUser(data);
            })
            .then((error) => {
                console.log(error);
            });
    }, [])

    const updateUser = () => {
        Mutation.UpdateUser(user)
        .then(data => {
            message.success('Utilisateur mis à jour !');
            setTimeout(() => {
                window.location.reload();
            }, 500)
        })
        .then((error) => {
        });
    }

    return (
        <div className="main">
            <div className="container">
                <div className="tableau">
                    <h2>Modification du profil</h2>
                    <div className="edition-profil">
                        <form>
                            <div className="bloc-left edit-profil-blocLeft">
                                <input value={user.prenom} onChange={e => setUser({...user, prenom: e.target.value})} type="text" placeholder="Branche" />
                                <input value={user.birthDate} onChange={e => setUser({...user, birthDate: e.target.value})} type="date" id="start" name="trip-start" />
                                <input value={user.street} onChange={e => setUser({...user, street: e.target.value})} type="text" placeholder="Rue de la paix" />
                                <input value={user.city} onChange={e => setUser({...user, city: e.target.value})} type="text" placeholder="Lausanne" />
                                <input value={user.email} disabled={ true } onChange={e => setUser({...user, email: e.target.value})} type="email" placeholder="info@heroes.swiss" />
                                {/* <input type="password" placeholder="Confirmer le mot de passe" /> */}
                            </div>
                            <div className="bloc-right edit-profil-blocRight">
                                <input value={user.nom} onChange={e => setUser({...user, nom: e.target.value})} type="text" placeholder="Didier" />
                                <input value={user.postcode} onChange={e => setUser({...user, postcode: e.target.value})} type="text" placeholder="1003" />
                                <input value={user.number} onChange={e => setUser({...user, number: e.target.value})} type="text" placeholder="4" />
                                <input value={user.country} onChange={e => setUser({...user, country: e.target.value})} disabled={ true } type="text" placeholder="Suisse" />
                                <input value={user.phone} onChange={e => setUser({...user, phone: e.target.value})} type="tel" placeholder="+00 11 22 33 44" />
                                {/* <input type="file" /> */}
                                {/* <input type="password" placeholder="Mot de passe" /> */}
                            </div>
                            <div className="submit-edit"><input onClick={ e => updateUser() } type="button" value="Enregistrer"  style={{background:"#ff0f2e"}}/></div>
                        </form>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default EditProfil

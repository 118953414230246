import React, {useState, useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import Querry from '../../../lib/Querry';
const _ = require('lodash');

function BarChart () {
    const [allService, setAllService] = useState({});
    const [allLabels, setAllLabels] = useState([]);
    const [allParts, setAllParts] = useState([]);

    const data = {
        labels: allLabels,
        datasets: [
          {
            label: 'Total',
            data: allParts,
            backgroundColor: [
                '#E32636',
                '#E9D66B',
                '#FF033E',
                '#00A6B4',
                '#6800B4',
                '#EFDECD',
                '#008000',
                '#007FFF',
                '#7FFF00',
            ],
            borderWidth: 1,
          },
        ],
      };
      
      const options = {
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
          display: false,
            position: 'right',
          },
          title: {
            display: true,
            text: 'Etats des missions',
          },
        },
      };
      

    useEffect(() => {

        let label = [], part = [];
    
        Querry.GetAllMissions().then(datas => {
          let dn = _.map(_.countBy(datas, "statut"), (val, key) => ({ statut: key, total: val }));
          dn && dn.forEach(d => {label.push(d.statut); part.push(d.total)});
          setAllService(dn)
          setAllLabels(label);
          setAllParts(part);
        }).catch(e => { console.log(e) })
      }, []);
    return( 
    <div className='header'>
      <div className='links'>
    <Bar data={data} options={options} />
    </div>
    </div>)
}

export default BarChart;
import React from 'react'

const EditPrestation = () => {
    return (
        <div className="main">
        <div className="container">
<div className="div-title">
<h2>Editer une prestation</h2>
</div>
            <div className="form-div">
                <form>
                <label>Nom</label>
                <input type="text"/>
                <label>Description</label>
                <input type="text"/>
                <label>Outils nécessaires</label>
                <select>
                    <option>outils </option>
                    <option>outils </option>
                    <option>outils </option>
                    <option>outils </option>
                    <option>outils </option>
                </select>
                <label>Conditions générales d'utilisation</label>
                <textarea></textarea> 
                <label>Service</label>
                <input type="text" placeholder="Bricoleur"/>
                <input style={{width:"20%",background:"#383495", color:"#FFF"}} type="submit" value="Enregistrer" />
                <input style={{width:"20%",background:"#ff0f2e", color:"#FFF"}} type="submit" value="Supprimer" />
                </form>
              
            </div>
        </div>
        
    </div>
    )
}

export default EditPrestation

import React from 'react'
import { Link } from 'react-router-dom'
const ConfigurationParams = () => {
    return (
        <div className="main">
            <div className="container">
                <div className="configuration-params">
                    <h2>Panneau de configuration</h2>
                    <div className="bloc-cta-conf">
                        <div className="bloc-cta-content">
                            <di v className="cta-aide">
                               <p><Link style={{color:"#FFF"}} to="Editer_info_contact">Info contact</Link> </p>
                            </di>
                            <div className="cta-aide">
                              <p> <Link style={{color:"#FFF"}} to="Params_Condition_general">Condition général</Link></p>
                            </div>
                            <div className="cta-aide">
                              <p><Link style={{color:"#FFF"}} to="Editer_Qui_sommes_nous">Qui sommes-nous </Link></p>
                            </div>
                            <div className="cta-aide">
                              <p><Link style={{color:"#FFF"}} to="Editer_suivi_des_données">Suivi des données</Link></p>
                            </div>
                            <div className="cta-aide">
                              <p><Link style={{color:"#FFF"}} to="Params_Aide_faq">questions/réponses</Link></p>
                            </div>
                            <div className="cta-aide">
                              <p><Link style={{color:"#FFF"}} to="Params_URL">URL</Link></p>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default ConfigurationParams

import React, {useState, useEffect} from 'react';
import { Pie} from 'react-chartjs-2';
import Querry from '../../../lib/Querry';
const _ = require('lodash');

function PieChart() {

    const [allService, setAllService] = useState({});
    const [allLabels, setAllLabels] = useState([]);
    const [allParts, setAllParts] = useState([]);

    
    const data = {
      labels: allLabels,
      datasets: [
        {
          label: 'Average Population',
          backgroundColor: [
            '#E32636',
            '#E9D66B',
            '#FF033E',
            '#00A6B4',
            '#6800B4',
            '#EFDECD',
            '#008000',
            '#007FFF',
            '#7FFF00',
          ],
          data: allParts
        }
      ]
    }

    useEffect(() => {

      let label = [], part = [];

      Querry.GetAllMissions().then(datas => {
        let dn = _.map(_.countBy(datas, "statut"), (val, key) => ({ statut: key, total: val }));
        dn && dn.forEach(d => {label.push(d.statut); part.push(d.total)});
        setAllService(dn)
        setAllLabels(label);
        setAllParts(part);
      }).catch(e => { console.log(e) })
    }, [])

    return (
      <div>
        <Pie
          data={data}
          options={{
            title: {
              display: true,
              text: 'Average Population PIE Chart',
              fontSize: 20
            },
            legend: {
              display: true,
              position: 'right'
            }
          }}
        />
      </div>
    )
}

export default PieChart;
import React from 'react'

const AjoutPrestation = () => {
    return (
        <div className="main">
            <div className="container">
<div className="div-title">
<h2>Ajouter une prestation</h2>
</div>
                <div className="form-div">
                    <form>
                    <label>Nom</label>
                    <input type="text"/>
                    <label>Description</label>
                    <input type="text"/>
                    <label>Tarif schema</label> 
                    <input type="text"/>
                    <label>Outils nécessaires</label>
                    <input type="text"/>
                    <label>Conditions générales d'utilisation</label>
                    <input type="text"/>
                    <input style={{width:"20%",background:"#383495", color:"#FFF"}} type="submit" value="Enregistrer" />
                    </form>
                  
                </div>
            </div>
            
        </div>
    )
}

export default AjoutPrestation

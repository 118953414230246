import React from 'react'
import Heros from '../../../assets/images/test.png'
const EditService = () => {
    return (
        <div className="main">
            <div className="container">
                <div className="modif-service">
                   <div style={{overflow:"hidden"}}>
                   <h2 style={{padding:"20px 0"}}>
                        Edition d'un service
                    </h2>
                    <img src={Heros} alt="img service"/>
                   </div>
                    <div className="edit-name">
                        <label htmlFor="name">Nom</label>
                        <input type="text" name="name" placeholder="Bricoleur"/>
                    </div>
                    <div className="edit-picture">
                        <label htmlFor="photo">Photo</label>
                        <input name="photo" type="file"/>
                    </div>
                </div>
                <div className="cta-bottom-buttons" style={{paddingTop:"20px"}}>
          <button  className="action-button" style={{width:"20%",background:"#383495"}}>Enregistrer</button>
          <button  className="action-button" style={{width:"20%",background:"#ff0f2e"}}  >Supprimer</button>
      </div>
      <div style={{clear:"both"}}></div>
      <div className="modif-service">
            
                    <div className="edit-name prix">
                        <label htmlFor="name">Prix</label>
                        <input type="text" name="name" placeholder="0"/>
                    </div>
                    <div className="edit-picture ">
                        <label htmlFor="shema">Shema de tarification</label>
                        <input name="shema" type="text" placeholder="Tarif kilométre"/>
                    </div>
                </div>
                <button  className="action-button" style={{width:"20%",background:"#383495"}}>Appliquer pour Toutes les Prestations</button>
            </div>
        </div>
    )
}

export default EditService

import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Form, Input, Button, Checkbox, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Mutation from '../../lib/Mutation';
import Querry from '../../lib/Querry';
import Api from '../../lib/Api';

const ForgotPassword = (props) => {

    const initialValues = {
        password: "",
        confirmePassword: "",


    }
    const [isLinkValid, setIsLinkValid] = useState(false)
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const [btnColor, setBtnColor] = useState("#FB7183")
    const [isDisabled, setIsDisabled] = useState(true)
    const [login, setLogin] = useState(initialValues);

    useEffect(() => {
        if (params.idUser && params.token) {
            Querry.VerifieTokenForgotPassword(params).then(result => {
                setLogin({ ...login, id: params.idUser })
                let dateExpire = new Date(result[0].expireAtCodeForgotPassword);
                let dateNow = new Date();
                if (dateExpire >= dateNow)
                    setIsLinkValid(true);
            }).catch(err => console.log("error", err))
        } else {

        }
    }, [])
    const checkEmptyFields = (e) => {
        let error = false;
        if ((e.target.name === "confirmePassword" && e.target.value !== "" && login.password !== "") || (e.target.name === "password" && e.target.value !== "" && login.confirmePassword !== "")) {
            if ((e.target.name === "confirmePassword" && e.target.value .trim()) === "" || ( e.target.name === "password" && e.target.value.trim() === "") ) {
                error = true;
                setIsDisabled(true)
                return;
            }

            if (!error) {
                setBtnColor("");
                setIsDisabled(false)
                return;
            }
        } else {
            setIsDisabled(true)
            return;
        }
    }
    const handleChangePassword = () => {
        if (login.password !== login.confirmePassword) {
            message.error('Les mots entrés ne sont pas identiques');
        } else if(login.password.length < 8){
              message.error("Votre mot de passe doit comporter 8 caractères minimum.")
        }else
            Mutation.ChangePassword(login).then((login) => {
                message.error('Votre mot de passe a été réinitialisé avec succés !');
                setTimeout(()=>{
                    history.replace('/Log-in');
                },1000)
            })
                .catch((error) => {
                    message.error('Erreur lors de la réinitialisation du mot de passe');
                })
    }

    return (
        <div className="main">
            <div className="container">
                <div style={{
                    overflow: 'hidden',
                    width: '100%',
                    maxWidth: '320px',
                    margin: '10% auto',
                    padding: '25px',
                }}>
                    {/* <div className="header-cont">
                        <img style={{ width: '50%' }} src={splash} alt="splach" />
                    </div> */}
                    {isLinkValid ?
                        <div className="">
                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={initialValues}
                                onFinish={props.onFinish}
                                onFinishFailed={props.onFinishFailed}
                            >
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Veuillez entrer votre mot de passe",
                                        },
                                    ]}
                                >
                                    <Input
                                        value={login.password}
                                        onChange={e => { setLogin({ ...login, password: e.target.value }); checkEmptyFields(e) }}
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        type="password"
                                        name="password"
                                        placeholder="Mot de passe" />
                                </Form.Item>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Veuillez confirmer votre mot de passe',
                                        },
                                    ]}
                                >
                                    <Input
                                        value={login.confirmePassword}
                                        prefix={<LockOutlined className="site-form-item-icon" />}
                                        onChange={e => { setLogin({ ...login, confirmePassword: e.target.value }); checkEmptyFields(e) }}
                                        type="password"
                                        placeholder="Confirmer votre mot de passe"
                                        name="confirmePassword"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    {/* <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Se souvenir de moi</Checkbox>
        </Form.Item> */}
                                </Form.Item>

                                <Form.Item>
                                    <div className="se-connecter" is={isDisabled}>
                                        <Button onClick={e => handleChangePassword()} type="primary" htmlType="submit" className="login-form-button" disabled={isDisabled}>
                                            Valider
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>
                        :
                        <div>
                            Ce lien a expiré veuillez demander un autre lien, pour reinitialiser votre mot de passe.
                        </div>
                    }

                </div>

            </div>
        </div>

    )
}

export default ForgotPassword

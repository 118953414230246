import React, { Component } from 'react'
import { Table, Modal, message } from 'antd';
import reqwest from 'reqwest';
import { Link } from 'react-router-dom';
import Querry from '../../../lib/Querry';
import Mutation from '../../../lib/Mutation';
import { CSVLink, CSVDownload } from 'react-csv';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ExclamationCircleOutlined } from '@ant-design/icons';

var _ = require('lodash');

const getRandomuserParams = params => {
  return {
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  };
};

const { confirm } = Modal;

class AvisRecus extends Component {
  state = {
    csvData: [],
    pagination: {
      current: 1,
      pageSize: 7,
    },
    loading: false,
    
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }
  handleTableChange = (pagination, filters, sorter) => {
    let allAvis = [...this.state.data]
        if (pagination && pagination.filters && pagination.filters !== undefined && pagination.filters.length > 0) {
            allAvis = allAvis.filter(e => {
                let date = new Date(e.dateCreation)
                let dateFact = `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
                return e.content.toLowerCase().includes(pagination.filters.toLowerCase()) ||
                    e.utilisateur &&  e.utilisateur.email && e.utilisateur.email.toLowerCase().includes(pagination.filters.toLowerCase()) ||
                    dateFact.toLowerCase().includes(pagination.filters.toLowerCase());
            })
        }else {this.fetch({})}   
        
        this.setState({
            data: allAvis && allAvis.length > 0 ? allAvis :  _.reverse(allAvis),
            pagination: {
                ...pagination,
                total: allAvis && allAvis.length ? allAvis.length : 0
            },
            filters,
        }); 
  };
  
  fetch = (params) => {
    this.setState({ loading: true });
    Querry.GETAVISRECUS(params.sortField ? { sortField: params.sortField + (params.sortOrder === 'ascend' ? '_ASC' : '_DESC') } : (params.filters ? { filters: params.filters } : {}))
      .then(data => {
        this.setState({
          loading: false,
          data: data,
          pagination: {
            ...params.pagination,
            total: data && data.length ? data.length : 0,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
        const dataFilters = [];
        data.forEach(avis => {
          let avi = { ...avis }
          avi.email = avis.utilisateur && avis.utilisateur.email ? avis.utilisateur.email : "";
          let nouvelleDate = new Date(avis.dateCreation)
          const dateAvis = `${('0' + (nouvelleDate.getDate())).slice(-2)}-${('0' + (nouvelleDate.getMonth() + 1)).slice(-2)}-${nouvelleDate.getFullYear()}  ${('0' + (nouvelleDate.getHours())).slice(-2)}:${('0' + (nouvelleDate.getMinutes())).slice(-2)} `
          avi.dateCreation = dateAvis;
          dataFilters.push(avi);

        });
        this.createCSVData(dataFilters);
      });
  };

  createCSVData(data) {
    let csvData = [
      ['Date création', 'Crée par', 'Contenu']
    ];
    data.forEach(element => {
      csvData.push(
        [element.dateCreation ? element.dateCreation : "", element.utilisateur && element.utilisateur.email ? element.utilisateur.email : "", element.content]
      )
    });
    this.setState({ ...this.state, csvData })
  }

  exportPDF() {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Liste des avis reçus";
    const headers = [["Date création", "Crée par", "contenu"]];

    const data = this.state.data.map(elt => [elt.dateCreation ? elt.dateCreation : "", elt.utilisateur && elt.utilisateur.email ? elt.utilisateur.email : "", elt.content]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("avisRecus" + new Date() + ".pdf");
  }

  deleteAvisRecu = (id) =>{
        confirm({
            title: 'Etes vous sûr de vouloir supprimer cet avis ?',
            icon: <ExclamationCircleOutlined />,
            content: 'Suppression Avis',
            onOk() {
              Mutation.deleteAvis(id).then(result=>{
                  message.success("L'avis a bien été supprimé !");
                  setTimeout(()=>{
                  window.location.reload()
                  },100)
              }).catch(er=>{
                message.error("Erreur de l'opération, veuillez réessayer plus tard !");
              })
            },
            onCancel(){
                  console.log('Cancel');
            },
        }); 
  }

  render() {

    const { data, pagination, loading, filters, sorter } = this.state;
    const columns = [

      {
        title: 'Date création',
        dataIndex: 'dateCreation',
        sorter: true,
        width: '15%',
        render: (dateCreation) => {
          let nouvelleDate = new Date(dateCreation)
          const dateAvis = `${('0' + (nouvelleDate.getDate())).slice(-2)}-${('0' + (nouvelleDate.getMonth() + 1)).slice(-2)}-${nouvelleDate.getFullYear()}  ${('0' + (nouvelleDate.getHours())).slice(-2)}:${('0' + (nouvelleDate.getMinutes())).slice(-2)} `
          return (
            <div>
              {dateAvis}
            </div>
          )
        },
    
      },
    
    
      {
        title: 'Créé par',
        dataIndex: 'utilisateur',
        sorter: true,
        render: (utilisateur) => {
          return (
            <div className="cta-action-buttons">
              <a><Link to={{ pathname:"/Dashboard/Profil/" +utilisateur && utilisateur.id ? utilisateur.id : '' },{ state: { profil: "" }}}>{utilisateur && utilisateur.email && utilisateur.email}</Link></a>
            </div>
          )
        },
        width: '15%',
      },
    
    
      {
        title: 'Contenu',
        dataIndex: 'content',
        sorter: true,
        width: '20%',
        heigth: '55%'
      },
    
      {
        title: 'Actions',
        dataIndex: 'id',
        sorter: true,
        render: (id) => {
          return (
            <div className="cta-action-buttons">
              <button className="action-button" style={{ cursor: "pointer", background: "#ff0f2e", width: "40%" }} onClick={() => this.deleteAvisRecu(id)}>Supprimer</button>
            </div>
          )
        },
        width: '15%',
      },
    
    
    ];

    return (
      <>
        <div className="main">
          <div className="container">
              <h2>Liste des avis reçus</h2>
            <div className="search-barre">
              <span>Recherche : </span> <input 
              onChange={e => this.handleTableChange({filters: e.target.value }, filters, sorter)} 
              placeholder = "   Tapez du texte ..."
              type="search" 
              />

            </div>

            <Table
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              onExpand={Actions => {
                return {
                  onClick: () => {console.log("fonction appelé")}, // click header row
                };
              }}
            />
            <div className="cta-bottom-buttons" style={{ paddingTop: "20px" }}>
              <button className="action-button" onClick={() => this.exportPDF()} style={{ width: "20%", background: "#383495" }} >PDF</button>
              <CSVLink data={this.state.csvData} ><button className="action-button" style={{ width: "20%", background: "#383495" }}  >CSV</button></CSVLink>

            </div>
          </div>

        </div>
      </>

    )
  }

}

export default AvisRecus

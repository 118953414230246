import React, { Component } from 'react'
import { Table, Modal } from 'antd';
import reqwest from 'reqwest';
import Querry from '../../../lib/Querry';
import {
    ExclamationCircleOutlined, CaretLeftOutlined
} from '@ant-design/icons';

import { Button } from 'antd';

const columnsDemande = [
    {
        title: 'Option',
        dataIndex: 'option',
        sorter: true,
        width: '20%',

    },
    {
        title: 'Valeur',
        dataIndex: 'valeur',
        width: '50%',
        sorter: true,
    },
];

const columnsNdf = [
    {
        title: 'Description',
        dataIndex: 'description',
        sorter: true,
        width: '20%',

    },
    {
        title: 'Prix',
        dataIndex: 'prix',
        width: '20%',
        sorter: true,
    },
    {
        title: 'TVA',
        dataIndex: 'TVA',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Statut',
        dataIndex: 'statut',
        width: '20%',
        sorter: true,
    },


];

const columnsTempSup = [
    {
        title: 'Heure',
        dataIndex: 'heure',
        sorter: true,
        width: '20%',

    },
    {
        title: 'Minute',
        dataIndex: 'minute',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Prix',
        dataIndex: 'prix',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Reverse prestataire',
        dataIndex: 'reversePrestataire',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Statut',
        dataIndex: 'statut',
        width: '20%',
        sorter: true,
    },

];

const columnsDmdeChangeHourPresta = [
    {
        title: 'Nouvelle date',
        dataIndex: 'nouvelleDate',
        sorter: true,
        width: '20%',
        render: (nouvelleDate) => {
            let date = new Date(nouvelleDate)
            return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
        },

    },
    {
        title: 'Nouvelle heure',
        dataIndex: 'nouvelleHeure',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Total facturer',
        dataIndex: 'totalFacturer',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Reverse prestataire',
        dataIndex: 'reversePrestataire',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Statut',
        dataIndex: 'statut',
        width: '20%',
        sorter: true,
    },

];

const columnsAccepteDemande = [
    {
        title: 'Statut',
        dataIndex: 'statut',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Nom',
        dataIndex: 'prestataire',
        sorter: true,
        width: '20%',
        sorter: true,
        render: (prestataire) => {
            return prestataire && prestataire.nom;
        }
    },
    {
        title: 'Prenom',
        dataIndex: 'prestataire',
        width: '20%',
        sorter: true,
        render: (prestataire) => {
            return prestataire && prestataire.prenom
        }
    },
    {
        title: 'Email',
        dataIndex: 'prestataire',
        width: '20%',
        sorter: true,
        render: (prestataire) => {
            return prestataire && prestataire.email
        }
    },
    {
        title: 'NOte moyenne',
        dataIndex: 'prestataire',
        width: '20%',
        sorter: true,
        render: (prestataire) => {
            return prestataire && prestataire.noteMoyenne + " / 5"
        }
    },

];

const columnsDmdeChangeHourManda = [
    {
        title: 'Nouvelle date',
        dataIndex: 'nouvelleDate',
        sorter: true,
        width: '20%',
        render: (nouvelleDate) => {
            let date = new Date(nouvelleDate)
            return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
        },
    },
    {
        title: 'Nouvelle heure',
        dataIndex: 'nouvelleHeure',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Total facturé',
        dataIndex: 'totalFacturer',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Reverse prestataire',
        dataIndex: 'reversePrestataire',
        width: '20%',
        sorter: true,
    },
    {
        title: 'Statut',
        dataIndex: 'statut',
        width: '20%',
        sorter: true,
    },


];


const getRandomuserParams = params => {
    return {
        results: params.pagination.pageSize,
        page: params.pagination.current,
        ...params,
    };
};
const { confirm } = Modal;

class DetailDemande extends Component {
    state = {
        data: [],
        pagination: {
            current: 1,
            pageSize: 10,
        },
        loading: false,
        prestation: null,
        fraisMateriels: [],
        fraisTemps: [],
        mandataireDemandeChangementHoraire: null,
        demandeChangementHoraires: [],
        acceptationPrestations: []

    };
    componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
        this.getPrestationById();
    }
    handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };
    fetch = (params = {}) => {
        this.setState({ loading: true });
        reqwest({
            url: 'https://randomuser.me/api',
            method: 'get',
            type: 'json',
            data: getRandomuserParams(params),
        }).then(data => {
            this.setState({
                loading: false,
                data: data.results,
                pagination: {
                    ...params.pagination,
                    total: 200,
                    // 200 is mock data, you should read it from server
                    // total: data.totalCount,
                },
            });
        });
    };

    getPrestationById() {
        const id = this.props && this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : null;
        if (id)
            Querry.GETPRESTATIONBYID(id).then(prestation => {
                this.setState({ prestation });
                this.setState({
                    fraisMateriels: prestation.fraisMateriels,
                    fraisTemps: prestation.fraisTemps,
                    demandeChangementHoraires: prestation.demandeChangementHoraires,
                    mandataireDemandeChangementHoraire: prestation.mandataireDemandeChangementHoraire,
                    acceptationPrestations: prestation.acceptationPrestations
                })
            }).catch(er => console.log(er))
    }
    getDate = (dateMission) => {
        let date = new Date(dateMission);
        return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
    }
    getDateAnnulation = (dateMission) => {
        let date = new Date(dateMission);
        return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    }

    goBack = () => {
        window.history.back();
    }

    showConfirm = () => {
        const history = this.props.history;
        const userId = this.state.userId
        confirm({
            title: 'Etes vous sûr de vouloir supprimer cette mission ?',
            icon: <ExclamationCircleOutlined />,
            content: 'Fonctionnalité en cours de développement',
            onOk() {
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    getStatut = (statut) => {
        switch (statut) {
            case 'Demarrage_Confirme': return 'Demarrage confirmé';
            case 'En_Attente': return 'En attente';
            case 'Annule': return 'Annulée';
            case 'Accepte': return 'Acceptée';
            case 'En_cours': return 'En cours';
            case 'Termine': return 'Terminée';
            case 'Valide': return 'Validée';
            case 'Demarrer': return 'Demarrée';
            case 'Demarrage_Conteste': return 'Demarrage contesté';
            case 'Termine_Confirme': return 'Terminée et confirmée';
            default: return statut;
        }
    }

    getStatutPaiement = (statut) => {
        switch (statut) {
            case 'requires_payment_method': return 'A confirmer';
            case 'requires_confirmation': return 'A confirmer';
            case 'canceled': return 'Annulé';
            case 'succeeded': return 'Réussi';
            case 'failed_connection': return 'Echoué';
            case 'no_pay': return 'A payer';
            case 'failed': return 'Echoué';
            case 'waiting_for_payment': return 'En attente';
            default: return statut;
        }
    }

    render() {
        const { data, pagination, loading } = this.state;

        return (
            <div className="main">
                <div className="container">
                    <h2>Détails de la mission</h2>
                    <div className="tableau">
                        <table className="tableau-info">
                            {this.state.prestation &&
                                <tbody>
                                    <tr>
                                        <td className="info-title"><b>Statut de la mission</b></td>
                                        <td className="info-data" style={{ color: "red", fontSize: "16px" }}>{this.getStatut(this.state.prestation.statut)}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Statut du paiement</b></td>
                                        <td className="info-data" style={{ color: "red", fontSize: "16px" }}>{this.getStatutPaiement(this.state.prestation.typeStatusPayment)}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Prestation</b></td>
                                        <td className="info-data">{this.state.prestation.service && this.state.prestation.service.titre && this.state.prestation.service.titre}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Catégorie service</b></td>
                                        <td className="info-data">{this.state.prestation.choix && this.state.prestation.choix.categorie && this.state.prestation.choix.categorie.nom && this.state.prestation.choix.categorie.nom}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Model</b></td>
                                        <td className="info-data">{this.state.prestation.service && this.state.prestation.service.model && this.state.prestation.service.model && this.state.prestation.service.model.nom && this.state.prestation.service.model.nom}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Prenom, nom et email du mandataire</b></td>
                                        <td className="info-data">{this.state.prestation.mandataire ? this.state.prestation.mandataire.prenom + " " + this.state.prestation.mandataire.nom + " " + this.state.prestation.mandataire.email : ""}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Prenom, nom et email du prestataire</b></td>
                                        <td className="info-data">{this.state.prestation.prestataire ? this.state.prestation.prestataire.prenom + " " + this.state.prestation.prestataire.nom + " " + this.state.prestation.prestataire.email : "pas de prestataire"}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Date du moment de la commande</b></td>
                                        <td className="info-data">{this.getDate(this.state.prestation.dateMomentCommande) + "  "}{this.state.prestation.heureMomentCommande && this.state.prestation.heureMomentCommande}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Date de la prestation</b></td>
                                        <td className="info-data">{this.getDate(this.state.prestation.dateDeLaPrestation) + "  "}{this.state.prestation.heurePrestation && this.state.prestation.heurePrestation}</td>
                                    </tr>
                                    {
                                        this.state.prestation.statut == "Annule" &&  this.state.prestation.dateAnnulation && 
                                        <tr>
                                            <td className="info-title"><b>Date annulation de prestation</b></td>
                                            <td className="info-data">{this.getDateAnnulation(this.state.prestation.dateAnnulation)}</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td className="info-title"><b>Adresse de la mission</b></td>
                                        <td className="info-data">{this.state.prestation.adresse && this.state.prestation.adresse.formattedAddress && this.state.prestation.adresse.formattedAddress}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Description de la mission</b></td>
                                        <td className="info-data">{this.state.prestation.description && this.state.prestation.description}</td>
                                    </tr>
                                    <tr>
                                        <td className="info-title"><b>Total facturer</b></td>
                                        <td className="info-data">{this.state.prestation.totalFacturer && this.state.prestation.totalFacturer} CHF</td>
                                    </tr>
                                    {this.state.prestation.prestataire &&
                                        <tr>
                                            <td className="info-title"><b>Reverse prestataire</b></td>
                                            <td className="info-data">{this.state.prestation.reversePrestataire && this.state.prestation.reversePrestataire} CHF</td>
                                        </tr>
                                    }

                                </tbody>
                            }
                        </table>
                        {this.state.prestation && this.state.prestation.statut === "Accepte" && this.state.acceptationPrestations && this.state.acceptationPrestations[0] &&
                            <div className="bloc">
                                <div className="bloc-left">
                                    <div className="profil-title">
                                        <h3>Les prestataires qui ont accepté la mission</h3>
                                    </div>
                                </div>
                                <div className="bloc-right">
                                    <div className="listing-div">
                                        <div className="search-button">
                                            <span> Recherche</span> <input type="search" />
                                        </div>
                                        <Table columns={columnsAccepteDemande} rowKey={record => record.id}
                                            dataSource={this.state.acceptationPrestations}
                                            pagination={{
                                                current: 1,
                                                pageSize: 5,
                                                total: this.state.acceptationPrestations.length
                                            }}
                                            loading={loading}
                                            onChange={this.handleTableChange}
                                        />

                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.prestation && this.state.prestation.statut === "En_cours" && this.state.fraisMateriels && this.state.fraisMateriels[0] &&
                            <div className="bloc">
                                <div className="bloc-left">
                                    <div className="profil-title">
                                        <h3>Les frais matériels ajoutés par le prestataire</h3>
                                    </div>
                                </div>
                                <div className="bloc-right">
                                    <div className="listing-div">
                                        <div className="search-button">
                                            <span> Recherche</span> <input type="search" />
                                        </div>
                                        <Table columns={columnsNdf} rowKey={record => record.id}
                                            dataSource={this.state.fraisMateriels}
                                            pagination={{
                                                current: 1,
                                                pageSize: 5,
                                                total: this.state.fraisMateriels.length
                                            }}
                                            loading={loading}
                                            onChange={this.handleTableChange}
                                        />

                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.prestation && this.state.prestation.statut === "En_cours" && this.state.fraisMateriels && this.state.fraisMateriels[0] &&
                            <div className="bloc">
                                <div className="bloc-left">
                                    <div className="profil-title">
                                        <h3>Les temps supplémentaires ajoutés par le prestataire</h3>
                                    </div>
                                </div>
                                <div className="bloc-right">
                                    <div className="listing-div">
                                        <div className="search-button">
                                            <span> Recherche</span> <input type="search" />
                                        </div>
                                        <Table columns={columnsTempSup} rowKey={record => record.id}
                                            dataSource={this.state.fraisTemps}
                                            pagination={{
                                                current: 1,
                                                pageSize: 5,
                                                total: this.state.fraisTemps.length
                                            }}
                                            loading={loading}
                                            onChange={this.handleTableChange}
                                        />

                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.prestation && this.state.prestation.statut === "En_Attente" && this.state.demandeChangementHoraires && this.state.demandeChangementHoraires[0] &&
                            <div className="bloc">
                                <div className="bloc-left">
                                    <div className="profil-title">
                                        <h3>Les prestataires qui ont fait une demande de changement horaire</h3>
                                    </div>
                                </div>
                                <div className="bloc-right">
                                    <div className="listing-div">
                                        <div className="search-button">
                                            <span> Recherche</span> <input type="search" />
                                        </div>
                                        <Table columns={columnsDmdeChangeHourPresta} rowKey={record => record.id}
                                            dataSource={this.state.demandeChangementHoraires}
                                            pagination={{
                                                current: 1,
                                                pageSize: this.state.demandeChangementHoraires.length,
                                            }}
                                            loading={loading}
                                            onChange={this.handleTableChange}
                                        />

                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.prestation && this.state.prestation.statut === "En_cours" && this.state.mandataireDemandeChangementHoraire &&
                            <div className="bloc">
                                <div className="bloc-left">
                                    <div className="profil-title">
                                        <h3>Demande de changement horaire du mandataire</h3>
                                    </div>
                                </div>
                                <div className="bloc-right">
                                    <div className="listing-div">
                                        <div className="search-button">
                                            <span> Recherche</span> <input type="search" />
                                        </div>
                                        <Table columns={columnsDmdeChangeHourManda} rowKey={record => record.id}
                                            dataSource={this.state.mandataireDemandeChangementHoraire}
                                            pagination={{
                                                current: 1,
                                                pageSize: 5,
                                                total: 1
                                            }}
                                            loading={loading}
                                            onChange={this.handleTableChange}
                                        />

                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.prestation && this.state.prestation.statut === "En_cours" && this.state.demandeChangementHoraires && this.state.demandeChangementHoraires[0] &&
                            <div className="bloc">
                                <div className="bloc-left">
                                    <div className="profil-title">
                                        <h3>Le prestataire a fait une demande de changement horaire</h3>
                                    </div>
                                </div>
                                <div className="bloc-right">
                                    <div className="listing-div">
                                        <div className="search-button">
                                            <span> Recherche</span> <input type="search" />
                                        </div>
                                        <Table columns={columnsDmdeChangeHourPresta} rowKey={record => record.id}
                                            dataSource={this.state.demandeChangementHoraires}
                                            pagination={{
                                                current: 1,
                                                pageSize: 5,
                                                total: this.state.demandeChangementHoraires.length
                                            }}
                                            loading={loading}
                                            onChange={this.handleTableChange}
                                        />

                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                    <div className="cta-bottom-buttons" style={{ paddingTop: "20px" }}>
                        <Button type="primary" shape="circle" onClick={this.goBack} icon={<CaretLeftOutlined />} />
                        <button className="action-button" style={{ width: "20%", background: "#ff0f2e", cursor: "po" }} onClick={this.showConfirm}>Annuler la mission</button>
                    </div>
                </div>
            </div>

        )
    }
}

export default DetailDemande

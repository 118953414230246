import React, { useState, useEffect } from 'react'
import Querry from '../../lib/Querry';
import { Table, Modal } from 'antd';
import { Link } from 'react-router-dom';
import iconInfo from '../../assets/images/infoiconpng.png';


var _ = require('lodash');

const StatutFilters = [
    { value: 'Demarrage_Confirme', text: 'Demarrage confirmé' },
    { value: 'En_Attente', text: 'En attente' },
    { value: 'Annule', text: 'Annulée' },
    { value: 'Accepte', text: 'Acceptée' },
    { value: 'En_cours', text: 'En cours' },
    { value: 'Termine', text: 'Terminée' },
    { value: 'Valide', text: 'Validée' },
    { value: 'Demarrer', text: 'Demarrée' },
    { value: 'Demarrage_Conteste', text: 'Demarrage contesté' },
    { value: 'Termine_Confirme', text: 'Terminée et confirmée' }
];

const columnsMissions = [
    {
        title: 'Mission',
        dataIndex: 'titre',
        sorter: true,
        width: '10%',

    },
    // {
    //     title: ' Service',
    //     dataIndex: 'service',
    //     render: (service) => {
    //         return service.titre
    //     },
    //     width: '10%',
    // },
    {
        title: 'Date de la mission',
        dataIndex: 'dateDeLaPrestation',
        render: (dateDeLaPrestation) => {
            let date = new Date(dateDeLaPrestation)
            return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
        },
        sorter: true,
        width: '10%',
    },
    {
        title: 'Statut',
        dataIndex: 'statut',
        render: (statut) => {
            switch (statut) {
                case 'Demarrage_Confirme': return 'Demarrage confirmé';
                case 'En_Attente': return 'En attente';
                case 'Annule': return 'Annulée';
                case 'Accepte': return 'Acceptée';
                case 'En_cours': return 'En cours';
                case 'Termine': return 'Terminée';
                case 'Valide': return 'Validée';
                case 'Demarrer': return 'Demarrée';
                case 'Demarrage_Conteste': return 'Demarrage contesté';
                case 'Termine_Confirme': return 'Terminée et confirmée';
                default: return statut;
            }
        },
        filters: StatutFilters,
        defaultFilteredValue: [
            'Demarrage_Confirme',
            'En_Attente',
            'Annule',
            'Accepte',
            'En_cours',
            'Termine',
            'Valide',
            'Demarrer',
            'Demarrage_Conteste',
            'Termine_Confirme'
        ],
        sorter: true,
        width: '10%',
    },
    {
        title: 'Mandataire',
        dataIndex: 'mandataire',
        render: (mandataire) => {
            return (
                <div>
                    <a style={{ width: "40%" }} key={mandataire && mandataire.id ? mandataire.id : (new Date())}><Link to={{pathname: "/Dashboard/Profil/" + mandataire  && mandataire.id ?  mandataire.id : "", state: {profil: "MANDATAIRE", typeCompte: "mandataire"}}}>{mandataire && mandataire.email ? mandataire.email : "Pas de mandataire"}</Link></a>
                </div>
            )
        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Prestataire',
        dataIndex: 'prestataire',
        render: (prestataire) => {
            return (
                prestataire && prestataire.id ?
                    <div>
                        <a style={{ width: "40%" }} key={prestataire.id}><Link to={{ pathname:"/Dashboard/Profil/" + prestataire.id },{ state: { profil: "PRESTATAIRE" , typeCompte: "prestataire"}}}>{prestataire.email && prestataire.email}</Link></a>
                    </div>
                    :
                    <div style={{ color: "red" }}>{"pas de prestataire"}</div>
            )

        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Total facturer',
        dataIndex: 'totalFacturer',
        render: (totalFacturer) => {
            return 'CHF ' + totalFacturer
        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Reverse prestataire',
        dataIndex: 'reversePrestataire',
        render: (reversePrestataire) => {
            return 'CHF ' + reversePrestataire
        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Actions',
        dataIndex: 'id',
        fixed: 'right',
        filtered: true,
        render: (id) => {
            return (
                <div className="cta-action-buttons">
                    {/* <button className="action-button" style={{ background: "#383495", width: "40%" }}><Link to={"/Dashboard/Mandataires/" + id}>Détails</Link></button> */}
                    {/* <button className="action-button" style={{ background: "#ff0f2e", width: "40%" }}>Modifier</button> */}
                    <Link to={"/Dashboard/Mandataires/" + id}>
                       <img style={{ width: '50%' }} src={iconInfo} alt="info" />
                    </Link>
                </div>
            )
        },
        width: '10%',
    },

];

const Accueil = () => {
    const [prestationDone, setPrestationDone] = useState([]);
    const [prestationComing, setPrestationComing] = useState([]);
    const [CA, setCA] = useState(0);
    const [hours, setHours] = useState(0);
    
    const getMisionsToDay = ()=>{
        let date = new Date();
         date = `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
         Querry.getMisionsToDay({ date: date }).then(result=>{

         }).catch(er=>console.log("error", er))
    }

    useEffect(() => {
        Querry.PrestationMonthDone({})
        .then((data) => {
            setPrestationDone(data);
            handleCA(data)
        })
        .catch((err) => {
            console.log(err)
        })
        Querry.PrestationMonthComing({})
        .then((data) => {
            setPrestationComing(data);
            handleHoursRestant(data)
        })
        .catch((err) => {
            console.log(err)
        })
    }, []);

    const handleCA = (prestations) => {
        let CA = 0;
        prestations.forEach(prestation => {
            prestation.totalFacturer && prestation.reversePrestataire ? CA += prestation.totalFacturer - prestation.reversePrestataire : CA += 0;
        });
        setCA(CA);
    }

    const handleHoursRestant = (prestations) => {
        let hours = 0;                                                                                      
        prestations.forEach(prestation => {
            hours += getHoursByHeurePrestataire(prestation.dureeDeLaPrestation);
        });
        setHours(hours);
    }

    const getHoursByHeurePrestataire = (time) => {
        let duree = 0;
        if (time.includes('semaine')) {
            duree += (12 * 7 * parseInt(time.split(' ')[0]));
        }
        else if (time.includes('journée')) {
            if (time.split(' ')[0] === '1/2') {
                duree += 6;
            }
            else {
                duree += (12 * parseInt(time.split(' ')[0]));
            }
        }
        else if (time.includes('heure')) {
            duree += parseInt(time.split(' ')[0])
        }
        return duree;
    }

    return (
        <div className="main">

            <div className="content-container">

                    <div className ="content">
                        <div className="content-accueil">

                            <div className="home-title">
                                <p>
                                        Welcome to <br/><span style={{fontWeight:"bold"}} >HEROES</span>  Web App<br/>
                                </p>
                                <div className="sub-title">
                                <span>Statistiques mensuelles des prestataires</span>
                                </div>

                            </div>
                            <div className="home-content">
                                <div className="bloc">
                                    <span style={{textAlign:"center"}}>CA net du mois en cours</span>
                                    <h1 style={{fontWeight:"bold", textAlign:"center"}}>{CA.toFixed(2)}</h1>
                                </div>
                                <div className="bloc">
                                    <span style={{textAlign:"center"}}>
                                        Nombre de missions effectuées dans le mois
                                    </span>
                                    <h1 style={{fontWeight:"bold", textAlign:"center"}}>{prestationDone.length}</h1>
                                </div>
                                <div className="bloc">
                                    <span style={{textAlign:"center"}}>
                                        Nombre de missions à venir pour le mois
                                    </span>
                                    <h1 style={{fontWeight:"bold", textAlign:"center"}}>{prestationComing.length}</h1>
                                </div>
                                <div className="bloc">
                                    <span style={{textAlign:"center"}}>
                                        Nombre d’heures de travail effectuées pour le mois
                                    </span>
                                    <h1 style={{fontWeight:"bold", textAlign:"center"}}>{hours}</h1>
                                </div>
                            </div>
                        </div>

                    </div>
             </div>

         </div>

    )
}

export default Accueil

import React from 'react'
import { Layout, Menu } from 'antd';
import uuid from 'react-uuid'
import splash from '../assets/images/splash.png';
import 'antd/dist/antd.css'
import {
  MenuUnfoldOutlined, MenuFoldOutlined,
} from '@ant-design/icons';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { DashboardData } from './DashboardData'
import { Link, Route } from 'react-router-dom';
import Accueil from '../Components/Pages/Accueil';
import Calendrier from '../Components/Pages/Calendrier/Calendrier';
import ConfigurationParams from '../Components/Pages/ConfigurationParams/ConfigurationParams';
import Facture from '../Components/Pages/Facture/Facture';
import Mandataire from '../Components/Pages/Mandataire/Mandataire';
import DetailDemande from '../Components/Pages/Mission/DetailDemande';
import Mission from '../Components/Pages/Mission/Mission';
import NoteDeFrais from '../Components/Pages/NoteDeFrais/NoteDeFrais';
import TempsSup from '../Components/Pages/TempsSup/TempsSup';
import DetailNoteDeFrais from './Pages/NoteDeFrais/DetailNoteDeFrais';
import PrestataireLambda from '../Components/Pages/PrestataireLambda/PrestataireLambda';
import PrestataireLambdaEtProValide from '../Components/Pages/PrestataireLambdaEtProValide/PrestataireLambdaEtProValide';
import EditProfil from '../Components/Pages/PrestataireProValide/EditProfil';
import PrestataireProValide from '../Components/Pages/PrestataireProValide/PrestataireProValide';
import ProfilPro from '../Components/Pages/PrestataireProValide/ProfilPro';
import AjoutPrestation from '../Components/Pages/ServiceParams/AjoutPrestation';
import DetailPrestation from '../Components/Pages/ServiceParams/DetailPrestation';
import DetailService from '../Components/Pages/ServiceParams/DetailService';
import EditPrestation from '../Components/Pages/ServiceParams/EditPrestation';
import EditService from '../Components/Pages/ServiceParams/EditService';
import ServiceParams from '../Components/Pages/ServiceParams/ServiceParams';
import Statistique from '../Components/Pages/Statistique/Statistique';
// import Config from './Pages/ConfigurationParams/Config';
// import ParamsAideEtFaq from './Pages/ConfigurationParams/ParamsAideEtFaq';
// import EditAideEtFaq from './Pages/ConfigurationParams/EditAideEtFaq';
// import ConditionGeneral from './Pages/ConfigurationParams/ConditionGeneral';
// import AjoutQuestionParams from './Pages/ConfigurationParams/AjoutQuestionParams';
import EnDeveloppement from './Pages/EnDevelopement';
import AvisRecus from './Pages/AvisRecus/AvisRecus';
import DetailTempsSup from './Pages/TempsSup/DetailTempsSup';





const { Header, Sider, Content } = Layout;
class Dashboard extends React.Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (

      <Layout className='main'>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <p style={{ textAlign: 'center' }}>
            <img style={{ width: '60%' }} src={splash} />
          </p>
          <div className="logo"><span>HEROES</span>APP</div>
          <Menu theme="dark" defaultSelectedKeys={['1']} style={{backgroundColor: "#1b327e"}}>
            {
              DashboardData.map((item, key) => {
                return (
                  <Menu.Item className={item.cName} key={uuid()} icon={item.icon}>
                    <Link to={item.path}><span>{item.title}</span></Link>
                  </Menu.Item>                                                                                                                                                  
                )
              })
            }

          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <div className="burger">{React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              /* onClick: this.toggle,
            */            })}</div>
            <div className="headerButton"><DashboardHeader /></div>

          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            <Route exact path="/Dashboard/Accueil" component={Accueil} />
            <Route path="/Dashboard/Calendrier" component={Calendrier} />
            <Route path="/Dashboard/Factures" component={Facture} />
            <Route path="/Dashboard/Configurations" component={ConfigurationParams} />
            <Route exact path="/Dashboard/Mandataires" component={Mandataire} />
            <Route path="/Dashboard/Missions" component={Mission} />
            <Route path="/Dashboard/Note_de_frais" exact component={NoteDeFrais} />
            <Route path="/Dashboard/Temps_sup" exact component={TempsSup} />
            <Route path="/Dashboard/Prestataires_Lambda" component={PrestataireLambda} />
            <Route path="/Dashboard/Prestataires_Lambda_et_Pro_validés" component={PrestataireLambdaEtProValide} />
            <Route path="/Dashboard/Prestataires_Pro_validés" component={PrestataireProValide} />
            <Route path="/Dashboard/Paramétrage_des_services" component={ServiceParams} />
            <Route path="/Dashboard/Profil/:id" component={ProfilPro} />
            <Route path="/Dashboard/Statistiques" component={Statistique} />
            <Route path="/Dashboard/Edit_profil/:id" component={EditProfil} />
            <Route path="/Dashboard/Détail_services" component={DetailService} />
            <Route path="/Dashboard/Edit_services" component={EditService} />
            <Route path="/Dashboard/Ajout_prestations" component={AjoutPrestation} />
            <Route path="/Dashboard/Détail_prestations" component={DetailPrestation} />
            <Route path="/Dashboard/Edit_prestations" component={EditPrestation} />
            <Route path="/Dashboard/Mission/:id" component={DetailDemande} />
            <Route path="/Dashboard/EnDeveloppement" component={EnDeveloppement} />
            <Route path="/Dashboard/AvisRecus" component={AvisRecus} />
            <Route path="/Dashboard/Note_de_frais/:id" component={DetailNoteDeFrais} />
            <Route path="/Dashboard/Temps_sup/:id" component={DetailTempsSup} />
          </Content>
        </Layout>
      </Layout>
    );
  }
}
export default Dashboard
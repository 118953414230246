import React from 'react'
import {
    UserOutlined,
    HomeOutlined,
    FieldTimeOutlined,
    SnippetsOutlined,
    LineChartOutlined,
    CustomerServiceOutlined,
    WalletOutlined,
    SettingOutlined,
    CalendarOutlined,
    InfoCircleFilled

  } from '@ant-design/icons';
import Accueil from './Pages/Accueil';
import PrestataireProValide from './Pages/PrestataireProValide/PrestataireProValide';
import PrestataireLambda from './Pages/PrestataireLambda/PrestataireLambda';
import PrestataireLambdaEtProValide from './Pages/PrestataireLambdaEtProValide/PrestataireLambdaEtProValide';
import Mandataire from './Pages/Mandataire/Mandataire';
import Mission from './Pages/Mission/Mission';
import Facture from './Pages/Facture/Facture';
import Calendrier from './Pages/Calendrier/Calendrier';
import Statistique from './Pages/Statistique/Statistique';
import ServiceParams from './Pages/ServiceParams/ServiceParams';
import NoteDeFrais from './Pages/NoteDeFrais/NoteDeFrais';
import TempsSup from './Pages/TempsSup/TempsSup';
import AvisRecus from './Pages/AvisRecus/AvisRecus';
import ConfigurationParams from './Pages/ConfigurationParams/ConfigurationParams';

  export const DashboardData = [
    {
      title: 'Accueil',
      path: '/Dashboard/Accueil',
      icon: <HomeOutlined />,
      comp: <Accueil />,
      cName: 'Cta-title'

    },
    {
      title: 'Prestataires professionnels validés',
      path: '/Dashboard/Prestataires_Pro_validés',
      icon: <UserOutlined />,
      cName: 'Cta-title',
      comp: <PrestataireProValide />,
    },
    {
      title: 'Prestataires lambdas',
      path: '/Dashboard/Prestataires_Lambda',
      icon: <UserOutlined />,
      cName: 'Cta-title',
      comp: <PrestataireLambda />,
    },
    {
      title: 'Prestataires lambdas et pro à valider',
      path: '/Dashboard/Prestataires_Lambda_et_Pro_validés',
      icon: <UserOutlined />,
      cName: 'Cta-title',
      comp: <PrestataireLambdaEtProValide />
    },

    {
      title: 'Mandataires',
      path: '/Dashboard/Mandataires',
      icon: <UserOutlined />,
      cName: 'Cta-title',
      comp: <Mandataire />
    },
    {
      title: 'Missions',
      path: '/Dashboard/Missions',
      icon: <FieldTimeOutlined />,
      cName: 'Cta-title',
      comp: <Mission />
    },
    // {
      //     title:'Services',
      //     path:'/Dashboard/EnDeveloppement',
      //   //   path:'/Dashboard/Paramétrage_des_services',
      //     icon:<CustomerServiceOutlined />,
      //     cName:'Cta-title',
      //     comp:<ServiceParams/>
      // },
      {
          title:'Notes de frais',
          path:'/Dashboard/Note_de_frais',
          icon:<WalletOutlined />,
          cName:'Cta-title',
          comp: <NoteDeFrais/>
      },
      {
        title:'Temps sup',
        path:'/Dashboard/Temps_sup',
        icon:<FieldTimeOutlined />,
        cName:'Cta-title',
        comp: <TempsSup/>
    },
      {
        title:'Avis reçus',
        path:'/Dashboard/AvisRecus',
        icon:<InfoCircleFilled  />,
        cName:'Cta-title',
        comp: <AvisRecus/>
    },
      // {
      //     title:'Configuration',
      //     path:'/Dashboard/EnDeveloppement',
      //   // path:'/Dashboard/Configurations',
      //     icon:<SettingOutlined  />,
      //     cName:'Cta-title',
      //     comp: <ConfigurationParams/>
      // }
    {
        title:'Factures',
        path:'/Dashboard/Factures',
        icon:<SnippetsOutlined />,
        cName:'Cta-title',
        comp:<Facture/>
    },
    {
        title:'Calendrier',
        path:'/Dashboard/Calendrier',
        icon:<CalendarOutlined />,
        cName:'Cta-title',
        comp:<Calendrier/>
    },
   // {
     // title:'Configurations',
      //path:'/Dashboard/EnDeveloppement',
      //icon:<SettingOutlined />,
      //cName:'Cta-title',
      //comp:<Statistique/>
  //},
  {
    title: 'Statistiques',
    path: '/Dashboard/Statistiques',
    icon: <LineChartOutlined />,
    cName: 'Cta-title',
    comp: <Statistique />
  },
    

  ]

import React, {Component} from 'react'
import { Table } from 'antd';
import reqwest from 'reqwest';
import { Link } from 'react-router-dom';
const columns = [
    
    {
        title: 'Service',
        dataIndex: 'service',
        sorter: true,
        width: '20%',
        
      },

    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
      width: '20%',
    },

     {
        title: 'Actions',
        dataIndex: 'action',
        sorter: true,
        render: () => {
return(
    <div className="cta-action-buttons">
    <button className="action-button" style={{background:"#383495",width:"40%"}}><Link to="/Détail_services">Voir détails service</Link></button>
    <button className="action-button"  style={{background:"#383495",width:"40%"}}><Link to="/Edit_services">Modifier service</Link></button>
    <button className="action-button" style={{background:"#ff0f2e",width:"40%"}}>Supprimer service</button>
    </div>     
)  
        },
        width: '50%',
      },
  
  
  ];

  const getRandomuserParams = params => {
    return {
      results: params.pagination.pageSize,
      page: params.pagination.current,
      ...params,
    };
  };

class ServiceParams extends Component{

    state = {
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
        },
        loading: false,
      };
      componentDidMount() {
        const { pagination } = this.state;
        this.fetch({ pagination });
      }
      handleTableChange = (pagination, filters, sorter) => {
        this.fetch({
          sortField: sorter.field,
          sortOrder: sorter.order,
          pagination,
          ...filters,
        });
      };
      fetch = (params = {}) => {
        this.setState({ loading: true });
        reqwest({
          url: 'https://randomuser.me/api',
          method: 'get',
          type: 'json',
          data: getRandomuserParams(params),
        }).then(data => {
          this.setState({
            loading: false,
            data: data.results,
            pagination: {
              ...params.pagination,
              total: 200,
              // 200 is mock data, you should read it from server
              // total: data.totalCount,
            },
          });
        });
      };

 render(){
    const { data, pagination, loading } = this.state;
    return (
        <>
        <div className="main">
            <div className="container">
        
            <div className="pretataire-pro-title">
                <h2>Services</h2>
            </div>
            <div className="search-barre">
             <span>Recherche</span> <input type="search"/>
        
            </div>
        
        <Table 
                columns={columns}
                rowKey={record => record.login.uuid}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                onChange={this.handleTableChange}
              />
              <div className="cta-bottom-buttons">
                  <button className="action-button" style={{width:"20%",background:"#383495"}} ><Link to="/Ajout_Service">Ajouter service</Link></button>
                 
              </div>
            </div>
          
        </div>
        </>
    )
 }
}

export default ServiceParams

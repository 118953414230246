import React, { Component } from 'react'
import { Table, Select, Button } from 'antd';
import Querry from '../../../lib/Querry';
import { Link } from 'react-router-dom'
import EditPrestation from '../ServiceParams/EditPrestation';
import iconInfo from '../../../assets/images/infoiconpng.png';
import { SearchOutlined, RedoOutlined } from '@ant-design/icons';
import jsPDF from "jspdf";
import "jspdf-autotable";

var _ = require('lodash');

const { Option } = Select;
const StatutFilters = [
    { value: 'Demarrage_Confirme', text: 'Demarrage confirmé' },
    { value: 'En_Attente', text: 'En attente' },
    { value: 'Annule', text: 'Annulée' },
    { value: 'Accepte', text: 'Acceptée' },
    { value: 'En_cours', text: 'En cours' },
    { value: 'Termine', text: 'Terminée' },
    { value: 'Demarrer', text: 'Demarrée' },
    { value: 'Demarrage_Conteste', text: 'Demarrage contesté' },
    { value: 'Termine_Confirme', text: 'Terminée et confirmée' }
];

const ServiceFilters = [
    { value: 'Bricoleur', text: 'Bricoleur' },
    { value: 'Coursier', text: 'Coursier' },
    { value: 'Dog-sitting', text: 'Dog-sitting' },
    { value: 'Débarras', text: 'Débarras' },
    { value: 'Déménagement', text: 'Déménagement' },
    { value: 'Electricien', text: 'Electricien' },
    { value: 'Jardinage', text: 'Jardinage' },
    { value: 'Ménage', text: 'Ménage' },
    { value: 'Onglerie & brushing', text: 'Onglerie & brushing' },
    { value: 'Plomberie', text: 'Plomberie' },
    { value: 'Aide au déménagement', text: 'Aide au déménagement' }
];

const columnsMissions = [
    {
        title: 'Titre de la Mission',
        dataIndex: 'titre',
        sorter: true,
        width: '10%',

    },

    // {
    //     title: ' Service',
    //     dataIndex: 'service',
    //     render: (service) => {
    //         return service.titre
    //     },
    //     width: '10%',
    // },
    {
        title: 'Service effectué',
        dataIndex: 'service',
        render: (service) => {
            return service.titre
        },
        filters: ServiceFilters,
        sorter: true,
        width: '5%',
    },
    {
        title: 'Date de la mission',
        dataIndex: 'dateDeLaPrestation',
        render: (dateDeLaPrestation) => {
            let date = new Date(dateDeLaPrestation)
            return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
        },
        sorter: true,
        width: '10%',
    },
    {
        title: 'Localité',
        dataIndex: 'adresse',
        render: (adresse) => {
            return adresse.city
        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Statut',
        dataIndex: 'statut',
        render: (statut) => {
            switch (statut) {
                case 'Demarrage_Confirme': return 'Demarrage confirmé';
                case 'En_Attente': return 'En attente';
                case 'Annule': return 'Annulée';
                case 'Accepte': return 'Acceptée';
                case 'En_cours': return 'En cours';
                case 'Termine': return 'Terminée';
                case 'Demarrer': return 'Demarrée';
                case 'Demarrage_Conteste': return 'Demarrage contesté';
                case 'Termine_Confirme': return 'Terminée et confirmée';
                default: return statut;
            }
        },
        filters: StatutFilters,
        defaultFilteredValue: [],
        sorter: true,
        width: '10%',
    },
    {
        title: 'Mandataire',
        dataIndex: 'mandataire',
        render: (mandataire) => {
            return (
                <div>
                    <a style={{ width: "40%" }} key={mandataire && mandataire.id ? mandataire.id : (new Date())}><Link to={{pathname: "/Dashboard/Profil/" + mandataire && mandataire.id ? mandataire.id : "", state: {profil: "MANDATAIRE", typeCompte: "mandataire"} }}>{mandataire && mandataire.email ? mandataire.email : "Pas de mandataire"}</Link></a>
                </div>
            )
        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Prestataire',
        dataIndex: 'prestataire',
        render: (prestataire) => {
            return (
                prestataire && prestataire.id ?
                    <div>
                        <a style={{ width: "40%" }} key={prestataire.id}><Link to={{pathname: "/Dashboard/Profil/" + prestataire && prestataire.id ? prestataire.id : "", state: {profil: "PRESTATAIRE", typeCompte: "prestataire"} }}>{prestataire.email && prestataire.email}</Link></a>
                    </div>
                    :
                    <div style={{ color: "red" }}>{"Pas de prestataire"}</div>
            )

        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Total facturé',
        dataIndex: 'totalFacturer',
        render: (totalFacturer) => {
            return 'CHF ' + totalFacturer
        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Total reversé au prestataire',
        dataIndex: 'reversePrestataire',
        render: (reversePrestataire) => {
            return 'CHF ' + reversePrestataire
        },
        sorter: true,
        width: '5%',
    },
    {
        title: 'Actions',
        dataIndex: 'id',
        fixed: 'right',
        filtered: true,
        render: (id) => {
            return (
                <div className="cta-action-buttons">
                    {/* <button className="action-button" style={{ background: "#383495", width: "40%" }}><Link to={"/Dashboard/Mandataires/" + id}>Détails</Link></button> */}
                    {/* <button className="action-button" style={{ background: "#ff0f2e", width: "40%" }}>Modifier</button> */}
                    <Link to={"/Dashboard/Mission/" + id}>
                        <img style={{ width: '50%' }} src={iconInfo} alt="info" />
                    </Link>
                </div>
            )
        },
        width: '5%',
    },

];

const adr = [
    {
        service: '',
        adresse: ''
    }
];

function formatDate(dat) {
    let date = new Date(dat)
    return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
}

class Mission extends Component {
    state = {
        data: [],
        pagination: {
            current: 1,
            pageSize: 7,
        },
        selectedValue: '',
        filteredInfo: null,
        statut: [
            'Demarrage_Confirme',
            'En_Attente',
            'Annule',
            'Accepte',
            'En_cours',
            'Termine',
            'Demarrer',
            'Demarrage_Conteste',
            'Termine_Confirme'
        ],
        loading: false,
        service: [
            'Bricoleur',
            'Coursier',
            'Dog-sitting',
            'Débarras',
            'Déménagement',
            'Electricien',
            'Jardinage',
            'Ménage',
            'Onglerie & brushing',
            'Plomberie',
            'Aide au déménagement'
        ],
    };
    componentDidMount() {
        const { pagination, statut, service } = this.state;
        this.fetch({ pagination, statut, service });
    }

    handleReset = () => {
        let allMissions = [...this.state.allMissions];
        let {pagination} = this.state;
        document.getElementById('search').value = '';
        this.setState({
            data: allMissions,
            filteredInfo: null,
            pagination: {
                ...pagination,
                total: allMissions && allMissions.length ? allMissions.length : 0
            },
        });
    }

    handleTableChange = (pagination, filters, sorter) => {

        let allMissions = [...this.state.allMissions];

        if (sorter && sorter.order) {
            allMissions = _.sortBy(allMissions, [(o) => { return o[sorter.field]; }]);
        }
        if (filters && filters.statut && filters.statut.length > 0) {
            allMissions = allMissions.filter(e => filters.statut.includes(e.statut));
        }
        if (filters && filters.service && filters.service !== '' && filters.service !== undefined) {
            allMissions = allMissions.filter(e => filters.service.includes(e.service.titre));
        }
        if (pagination && pagination.filters && pagination.filters !== undefined && pagination.filters.length > 0) {
            const parts = pagination.filters.split(' ');
            parts.forEach(el => {
                allMissions = allMissions.filter(e => {
                    let date = new Date(e.dateDeLaPrestation)
                    let dateDeLaPrestationString = `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
                    return e.titre.toLowerCase().includes(el.toLowerCase()) ||
                        e.adresse && e.adresse.city && e.adresse.city.toLowerCase().includes(el.toLowerCase()) ||
                        dateDeLaPrestationString.toLowerCase().includes(el.toLowerCase()) ||
                        e.mandataire && e.mandataire.email && e.mandataire.email.toLowerCase().includes(el.toLowerCase()) ||
                        e.prestataire && e.prestataire.email && e.prestataire.email.toLowerCase().includes(el.toLowerCase());


                })
            })
        }
        this.setState({
            data: sorter && allMissions && allMissions.length > 0 && sorter.order === 'ascend' ? allMissions : (sorter && sorter.order && allMissions && allMissions.length > 0 ? _.reverse(allMissions) : allMissions),
            filteredInfo: filters,
            pagination: {
                ...pagination,
                total: allMissions && allMissions.length ? allMissions.length : 0
            },
        });
    };

    exportPDF() {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Listing des missions";
        const headers = [["SERVICE", "DATE", "PRIX", "ADRESSE", "ETAT"]];

        const data = this.state.data.map(elt => [elt.titre, formatDate(elt.dateDeLaPrestation), 'CHF ' + elt.totalFacturer, elt.adresse.city, elt.statut]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("missions" + new Date() + ".pdf");
    }

    fetch = (params = {}) => {
        this.setState({ loading: true });
        let statutString = '';
        let serviceString = params.service ? params.service : '';
        if (params.statut) {
            params.statut.forEach(st => {
                statutString += st + ', ';
            });
        }
        Querry.GetServices(params.sortField ?
            { statutString, serviceString, sortField: params.sortField + (params.sortOrder === 'ascend' ? '_ASC' : '_DESC') }
            : (params.pagination && params.pagination.filters ?
                { filters: params.pagination.filters }
                : { statutString, serviceString }))
            .then(data => {
                this.setState({
                    loading: false,
                    data: data,
                    allMissions: data,
                    pagination: {
                        ...params.pagination,
                        total: data && data.length ? data.length : 0,
                        // 200 is mock data, you should read it from server
                        // total: data.totalCount,
                    },
                });
            });
    };

    render() {
        let { filteredInfo } = this.state;
        filteredInfo = filteredInfo || {};

        const columnsMissions = [
            {
                title: 'Titre de la Mission',
                dataIndex: 'titre',
                sorter: true,
                width: '10%',

            },

            // {
            //     title: ' Service',
            //     dataIndex: 'service',
            //     render: (service) => {
            //         return service.titre
            //     },
            //     width: '10%',
            // },
            {
                title: 'Service effectué',
                dataIndex: 'service',
                render: (service) => {
                    return service.titre
                },
                filters: ServiceFilters,
                filteredValue: filteredInfo.service || null,
                sorter: true,
                width: '5%',
            },
            {
                title: 'Date du moment de la commande',
                dataIndex: 'dateMomentCommande',
                render: (dateMomentCommande) => {
                    let date = new Date(dateMomentCommande)
                    return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
                },
                sorter: true,
                width: '10%',
            },
            {
                title: 'Date de la prestation',
                dataIndex: 'dateDeLaPrestation',
                render: (dateDeLaPrestation) => {
                    let date = new Date(dateDeLaPrestation)
                    return `${('0' + (date.getDate())).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`;
                },
                sorter: true,
                width: '10%',
            },
            {
                title: 'Localité',
                dataIndex: 'adresse',
                render: (adresse) => {
                    return adresse.city
                },
                sorter: true,
                width: '5%',
            },
            {
                title: 'Statut',
                dataIndex: 'statut',
                render: (statut) => {
                    switch (statut) {
                        case 'Demarrage_Confirme': return 'Demarrage confirmé';
                        case 'En_Attente': return 'En attente';
                        case 'Annule': return 'Annulée';
                        case 'Accepte': return 'Acceptée';
                        case 'En_cours': return 'En cours';
                        case 'Termine': return 'Terminée';
                        case 'Demarrer': return 'Demarrée';
                        case 'Demarrage_Conteste': return 'Demarrage contesté';
                        case 'Termine_Confirme': return 'Terminée et confirmée';
                        default: return statut;
                    }
                },
                filters: StatutFilters,
                defaultFilteredValue: [],
                filteredValue: filteredInfo.statut || null,
                sorter: true,
                width: '10%',
            },
            {
                title: 'Mandataire',
                dataIndex: 'mandataire',
                render: (mandataire) => {
                    return (
                        <div>
                            <a style={{ width: "40%" }} key={mandataire && mandataire.id ? mandataire.id : (new Date())}><Link to={{pathname: "/Dashboard/Profil/" + mandataire && mandataire.id ? mandataire.id : "", state: {profil: "MANDATAIRE", typeCompte: "mandataire"} }}>{mandataire && mandataire.email ? mandataire.email : "Pas de mandataire"}</Link></a>
                        </div>
                    )
                },
                sorter: true,
                width: '5%',
            },
            {
                title: 'Prestataire',
                dataIndex: 'prestataire',
                render: (prestataire) => {
                    return (
                        prestataire && prestataire.id ?
                            <div>
                                <a style={{ width: "40%" }} key={prestataire.id}><Link to={{pathname: "/Dashboard/Profil/" + prestataire && prestataire.id ? prestataire.id : "", state: {profil: "PRESTATAIRE", typeCompte: "prestataire"} }}>{prestataire.email && prestataire.email}</Link></a>
                            </div>
                            :
                            <div style={{ color: "red" }}>{"Pas de prestataire"}</div>
                    )
                },
                sorter: true,
                width: '5%',
            },
            {
                title: 'Total facturé',
                dataIndex: 'totalFacturer',
                render: (totalFacturer) => {
                    return 'CHF ' + totalFacturer
                },
                sorter: true,
                width: '5%',
            },
            {
                title: 'Total reversé au prestataire',
                dataIndex: 'reversePrestataire',
                render: (reversePrestataire) => {
                    return 'CHF ' + reversePrestataire
                },
                sorter: true,
                width: '5%',
            },
            {
                title: 'Actions',
                dataIndex: 'id',
                fixed: 'right',
                filtered: true,
                render: (id) => {
                    return (
                        <div className="cta-action-buttons">
                            {/* <button className="action-button" style={{ background: "#383495", width: "40%" }}><Link to={"/Dashboard/Mandataires/" + id}>Détails</Link></button> */}
                            {/* <button className="action-button" style={{ background: "#ff0f2e", width: "40%" }}>Modifier</button> */}
                            <Link to={"/Dashboard/Mission/" + id}>
                                <img style={{ width: '50%' }} src={iconInfo} alt="info" />
                            </Link>
                        </div>
                    )
                },
                width: '5%',
            },

        ];

        const { data, pagination, loading, sorter } = this.state;
        return (
            <div className="main">
                <div className="container">
                    <div className="tableau">
                        <h2>Liste des missions</h2>
                        <div className="bloc">

                            <div style={{ width: "100%" }} className="bloc-right">
                                <div className="listing-div">
                                    <div className="search-button">
                                        <div className="search">
                                            <span> Recherche : </span>
                                            <input id="search" type="search" style={{ width: 700 }}
                                                placeholder="Rechercher par : ''Titre de la mission''  ''Date de la mission''  ''Localité''  ''Mandataire''  ''Prestataire''"
                                                onChange={e => this.handleTableChange({ filters: e.target.value }, filteredInfo, sorter)}
                                            />
                                            <Button
                                                type="text"
                                                shape="round"
                                                onClick={() => this.handleReset()}
                                                icon={<RedoOutlined />}
                                                danger
                                            >Enlever tous les filtres</Button>
                                            <br />
                                        </div>
                                    </div>
                                    <Table columns={columnsMissions}
                                        rowKey={record => record.id}
                                        dataSource={data}
                                        pagination={pagination}
                                        loading={loading}
                                        onChange={this.handleTableChange}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="cta-bottom-buttons" style={{ paddingTop: "20px" }}>
                        <button className="action-button" onClick={() => this.exportPDF()} style={{ width: "20%", background: "#383495" }} >Extraire la liste des demandes</button>

                    </div>
                </div>

            </div>

        )
    }
}

export default Mission

import React, { Component } from 'react'
import { DatePicker, Space, Table } from 'antd';
import moment, { now } from 'moment';
import Querry from '../../../lib/Querry';
import reqwest from 'reqwest';
import { Link } from 'react-router-dom';

const columns = [

  {
    title: 'Mission',
    dataIndex: 'titre',
    sorter: true,
    width: '20%',
  },

  {
    title: 'Adresse de la mission',
    dataIndex: 'adresse',
    sorter: true,
    width: '20%',
    render: (adresse) => {
      return (adresse && adresse.id ?
        <div>{adresse.formattedAddress}
        </div> : <div style={{ color: "red" }}>{"Champs vide"}</div>
      )

    },
  },

  {
    title: 'Date',
    dataIndex: 'dateDeLaPrestation',
    sorter: true,
    width: '10%',
    render: (dateDeLaPrestation) => {
      let date = new Date(dateDeLaPrestation)
      return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + (date.getDate())).slice(-2)}`;
    },
  },

  {
    title: 'Prestataire',
    dataIndex: 'prestataire',
    sorter: true,
    width: '15%',
    render: (prestataire) => {
      return (prestataire && prestataire.id ?
        <div>{prestataire.prenom + ' ' + prestataire.nom}
        </div> : <div style={{ color: "red" }}>{"Champs vide"}</div>
      )

    },
  },

  {
    title: 'Mandataire',
    dataIndex: 'mandataire',
    sorter: true,
    width: '15%',
    render: (mandataire) => {
      return (mandataire && mandataire.id ?
        <div>{mandataire.prenom + ' ' + mandataire.nom}
        </div> : <div style={{ color: "red" }}>{"Champs vide"}</div>
      )

    },
  },

  {
    title: 'Statut',
    dataIndex: 'statut',
    sorter: true,
    width: '10%',
    render: (statut) => {
      switch (statut) {
        case 'En_Attente': return 'En attente';
        case 'En_cours': return 'En cours';
        case 'Annule': return 'Annulée';
        default: return statut;
      }
  },

  },

  {
    title: 'Actions',
    dataIndex: 'id',
    sorter: true,
    filtered: true,
    render: (id) => {
      return (
        <div className="cta-action-buttons" key={id}>
          <button className="action-button" style={{ background: "#383495", width: "60%" }}>
            <Link to={"/Dashboard/Mission/" + id}>Détails</Link>
          </button>
        </div>
      )
    },
    width: '10%',
  },
];

const getRandomuserParams = params => {
  return {
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  };
};

const { RangePicker } = DatePicker;


class Calendrier extends Component {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    from: "1970-01-01",
    to: "1970-01-01"
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  handleTableChange = (pagination, filters, sorter, from, to) => {

    this.fetch({
      sortField: sorter && sorter.field ? sorter.field : '',
      sortOrder: sorter && sorter.order ? sorter.order : '',
      pagination: { ...pagination, pageSize: 7 },
      filters,
      from,
      to
    });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true });
    Querry.GetAllPrestations(params)
      .then(data => {
        this.setState({
          loading: false,
          data: data,
          pagination: {
            ...params.pagination,
            total: data && data.length ? data.length : 0,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      });
  };

  handleChange = (dates, dateStrings) => {
    if (dates && dateStrings) {
      this.setState({ ...this.state, from: dateStrings[0], to: dateStrings[1] });
      this.fetch({
        ...this.state, from: dateStrings[0], to: dateStrings[1]
      })
    } else {
      this.fetch();
    };
  }

  handlePagination = (pagination) => {
    if (pagination) {
      this.setState({ ...this.state, pagination });
      this.fetch({ ...this.state, pagination })
    } else {
      this.fetch();
    };
  }


  render() {

    const { data, pagination, loading } = this.state;

    return (
      <div>
        <div className="main">
          <div className="container">
              <h2>Etats des missions HEROES</h2>
            <div className="search-barre">
              <span><b>Période:     </b></span>
              <Space direction="vertical" size={12}>
                <RangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                  }}
                  //  onChange={e => this.handleTableChange({filters: e.target.value})}
                  onChange={this.handleChange}
                />
              </Space>
            </div>
            <Table
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handlePagination}
            />
          </div>
        </div>
      </div>);
  }
}

export default Calendrier
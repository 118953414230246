import React, { Component } from 'react'
import { Table } from 'antd';
import reqwest from 'reqwest';
import { Link } from 'react-router-dom';
import Querry from '../../../lib/Querry';

var _ = require('lodash');

const columns = [

  {
    title: 'Durée',
    dataIndex: 'duree',
    sorter: true,
    width: '20%',
  },

  {
    title: 'Prix',
    dataIndex: 'prix',
    sorter: true,
    width: '15%',
    render: (prix) => {
      return 'CHF ' + prix
    },
  },

  {
    title: 'Mission',
    dataIndex: 'prestation',
    sorter: true,
    render: (prestation) => {
      return (
        <div>
          <a key={prestation.id}><Link to={"/Dashboard/Mission/" + prestation.id}>{prestation.titre}</Link></a>
        </div>
      )
    },
    width: '15%',
  },
  {
    title: 'Prestataire',
    dataIndex: 'prestation',
    sorter: true,
    render: (prestation) => {
      return (
        <div>
          <a style={{ width: "40%" }} key={prestation.id}><Link to={{pathname:"/Dashboard/Profil/" + prestation.prestataire.id, state:{profil: "PRESTATAIRE", typeCompte: "prestataire"}}}>{prestation.prestataire.email}</Link></a>
        </div>
      )
    },
    width: '15%',
  },
  {
    title: 'Mandataire',
    dataIndex: 'prestation',
    sorter: true,
    render: (prestation) => {
      return (
        <div>
          <a style={{ width: "40%" }} key={prestation.id}><Link to={{pathname: "/Dashboard/Profil/" + prestation.mandataire.id, state: {profil: "MANDATAIRE", typeCompte: "mandataire"}}}>{prestation.mandataire.email}</Link></a>
        </div>
      )
    },
    width: '15%',
  },
  {
    title: 'Statut',
    dataIndex: 'statut',
    render: (statut) => {
      switch (statut) {
        case 'CONTESTER': return 'CONTESTE';
        case 'EN_COURS': return 'EN COURS';
        case 'REFUSER': return 'REFUSE';
        case 'VALIDER': return 'VALIDE';
        default: return statut;
      }
    },
    sorter: true,
    width: '10%',
  },


  {
    title: 'Actions',
    dataIndex: 'id',
    sorter: true,
    render: (id) => {
      return (
        <div className="cta-action-buttons">
          <button className="action-button" style={{ background: "#383495", width: "100%" }}><Link to={"/Dashboard/Temps_sup/" + id}>Détails</Link></button>
        </div>
      )
    },
    width: '40%',
  },


];

const getRandomuserParams = params => {
  return {
    results: params.pagination.pageSize,
    page: params.pagination.current,
    ...params,
  };
};

class TempsSup extends Component {
  state = {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
  };
  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  getTempsSup = (params) => {
    Querry.ListTempsSup().then(results => {
      var temps = [];
      results.forEach(ts => {
        var tempsSup = { ...ts };
        tempsSup.duree = tempsSup.heure + 'h : ' + tempsSup.minute + 'min';
        temps.push(tempsSup);
      });
      this.setState({
        loading: false,
        data: temps,
        pagination: {
          ...params.pagination,
          total: results && results.length ? results.length : 0,
          // 200 is mock data, you should read it from server
          // total: data.totalCount,
        },
      });
    }).catch(er => console.log(er))
  }

  handleTableChange = (pagination, filters, sorter) => {
    let allFactures = [...this.state.data]
    if (pagination && pagination.filters && pagination.filters !== undefined && pagination.filters.length > 0) {
      console.log('Pagin', pagination.filters)
      allFactures = allFactures.filter(e => {
        return e.statut.toLowerCase().includes(pagination.filters.toLowerCase()) ||
          e.prestation && e.prestation.titre && e.prestation.titre.toLowerCase().includes(pagination.filters.toLowerCase()) ||
          e.prestation && e.prestation.prestataire && e.prestation.prestataire.email.toLowerCase().includes(pagination.filters.toLowerCase()) ||
          e.prestation && e.prestation.mandataire && e.prestation.mandataire.email.toLowerCase().includes(pagination.filters.toLowerCase());
      })
    } else { this.fetch() }

    this.setState({
      data: allFactures && allFactures.length > 0 ? allFactures : _.reverse(allFactures),
      pagination: {
        ...pagination,
        total: allFactures && allFactures.length ? allFactures.length : 0
      },
      filters,
    });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true })
    this.getTempsSup(params);
  };

  render() {

    const { data, pagination, loading, filters, sorter } = this.state;

    return (
      <>
        <div className="main">
          <div className="container">
            <h2>Temps supplémentaire</h2>
            <div className="search-barre">
              <span>Recherche</span> <input
                type="search"
                placeholder="Tapez du texte ..."
                onChange={e => this.handleTableChange({ filters: e.target.value }, filters, sorter)}
              />

            </div>

            <Table
              columns={columns}
              rowKey={record => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
            />
            <div className="cta-bottom-buttons" style={{ paddingTop: "20px" }}>
              <button className="action-button" style={{ width: "20%", background: "#383495" }} >PDF</button>
              <button className="action-button" style={{ width: "20%", background: "#383495" }}  >XLS</button>

            </div>
          </div>

        </div>
      </>

    )
  }

}

export default TempsSup
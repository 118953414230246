import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import Dashboard from './Components/Dashboard'
import LoginForm from './Components/Pages/Authentication/LoginForm';
import ForgotPassword from './Components/Pages/ForgotPassword'

function App() {

  return (

    <Router >
      <Switch>
        <Route path="/Dashboard" component={Dashboard} />
        <Route exact path="/Log-in" component={LoginForm} />
        <Route  path="/Mot_de_passe_oublie/:idUser/:token" component={ForgotPassword} />
        <Route path="/" render={() => (
          <Redirect to="/Log-in" />
        )} />
      </Switch>

    </Router>
  );
}

export default App;
